import Vue from "vue";
import Vuex from "vuex";
import utilisateur from "./modules/utilisateur";
import entreprise from "./modules/entreprise";

Vue.use(Vuex);

// where we assemble modules and export the store
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  // https://vuex.vuejs.org/guide/strict.html
  strict: debug,
  // https://vuex.vuejs.org/guide/modules.html#namespacing
  state: {},
  mutations: {},
  actions: {},
  modules: {
    utilisateur,
    entreprise,
  },
});
