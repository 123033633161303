import httpClient from "@/api/httpClient";

const version = "v1";

const CHEMINS = {
  auth: `/authenticate`,
  entreprises: `/${version}/entreprises/:id`,
  utilisateur: `/${version}/utilisateur/:id`,
  utilisateurs: `/${version}/utilisateurs`,
  role: `/${version}/role/:id`,
  roles: `/${version}/roles`,
  projet: `/${version}/projet/:id`,
  projets: `/${version}/projets`,
  groupe: `/${version}/groupe/:id`,
  groupes: `/${version}/groupes`,
  membre: `/${version}/membreGroupe/:id`,
  membresParGroupe: `/${version}/membreGroupe/groupe/:idGroupe`,
  tache: `/${version}/tache/:id`,
  duplicateTache:`/${version}/dupliquertache/:id`,
  tacheParLabel: `/${version}/taches/label/:id`,
  tacheParProjet: `/${version}/taches/projet/:id`,
  tachesParProjets: `/${version}/taches/projets`,
  tachesParRessource: `/${version}/taches/ressource/:id`,
  tachesPourUneListeDeRessource: `/${version}/taches/ressources/:listIdRessource`,
  note: `/${version}/note/:id`,
  label: `/${version}/label/:id`,
  labels: `/${version}/labels`,
  lienLabel: `/${version}/lienLabel`,
  travailleur: `/${version}/travailleur/:id`,
  travailleursParTache: `/${version}/travailleurs/:idTache`,
  suppleant: `/${version}/suppleant/:id`,
  suppleantsParProjet: `/${version}/suppleants/:idProjet`,
  pointage: `/${version}/pointage/:id`,
  pointageSpecial: `/${version}/pointage_special/:id`,
  pointagesSpeciaux: `/${version}/pointages_speciaux`,
  pointagesParRessource: `/${version}/pointages/ressource/:idRessource`,
  pointagesParGroupeParPeriode: `/${version}/pointages/groupe/:idGroupe/:debut/:fin`,
  pointagesParProjetsParPeriode: `/${version}/pointages/projets/:listeProjets/:debut/:fin`,
  pointagesParRessourceParPeriode: `/${version}/pointages/ressource/:idRessource/:debut/:fin`,
  pointagesPourUneListeDeRessourceParPeriode: `/${version}/pointages/ressources/:listIdRessource/:debut/:fin`,
  absence: `/${version}/absence/:id`,
  absencesParRessourceParPeriode: `/${version}/absences/ressource/:idRessource/:debut/:fin`,
  absencesPourUneListeDeRessourceParPeriode: `/${version}/absences/ressources/:listIdRessource/:debut/:fin`,
  typesAbsence:`/${version}/typesabsence`,
  tempsParTache: `/${version}/reporting/tache/temps/:idTache`,
  repartitionParTache: `/${version}/reporting/tache/repartition/:idTache`,
  tempsParProjet: `/${version}/reporting/projet/temps/:idProjet`,
  repartitionParProjet: `/${version}/reporting/projet/repartition/:idProjet`,
  profils: `/${version}/profils/:idRole/:idEntreprise`,
  exportPointage: `/${version}/exportpointage/:idRessource/:debut/:fin`,
  changementRole: `/${version}/changementrole/:idRessource`,
};

const kuberact = {
  versionApi: version,
  authentification(username, password) {
    return httpClient.post(CHEMINS.auth, { username, password });
  },
  getEntreprises() {
    return httpClient.get(CHEMINS.entreprises.replace("/:id", ""));
  },
  updateEntreprise(id, entreprise) {
    return httpClient.put(CHEMINS.entreprises.replace(":id", id), entreprise);
  },
  getUtilisateur(id) {
    return httpClient.get(CHEMINS.utilisateur.replace(":id", id));
  },
  createUtilisateur(utilisateur) {
    return httpClient.post(
      CHEMINS.utilisateur.replace("/:id", ""),
      utilisateur
    );
  },
  updateUtilisateur(id, utilisateur) {
    return httpClient.put(CHEMINS.utilisateur.replace(":id", id), utilisateur);
  },
  getUtilisateurs() {
    return httpClient.get(CHEMINS.utilisateurs);
  },
  getRole(id) {
    return httpClient.get(CHEMINS.role.replace(":id", id));
  },
  getRoles() {
    return httpClient.get(CHEMINS.roles);
  },
  getProjet(id) {
    return httpClient.get(CHEMINS.projet.replace(":id", id));
  },
  createProjet(projet) {
    return httpClient.post(CHEMINS.projet.replace("/:id", ""), projet);
  },
  updateProjet(id, projet) {
    return httpClient.put(CHEMINS.projet.replace(":id", id), projet);
  },
  destroyProjet(id) {
    return httpClient.delete(CHEMINS.projet.replace(":id", id));
  },
  getProjets() {
    return httpClient.get(CHEMINS.projets);
  },
  getGroupe(id) {
    return httpClient.get(CHEMINS.groupe.replace(":id", id));
  },
  createGroupe(groupe) {
    return httpClient.post(CHEMINS.groupe.replace("/:id", ""), groupe);
  },
  updateGroupe(id, groupe) {
    return httpClient.put(CHEMINS.groupe.replace(":id", id), groupe);
  },
  destroyGroupe(id) {
    return httpClient.delete(CHEMINS.groupe.replace(":id", id));
  },
  getGroupes() {
    return httpClient.get(CHEMINS.groupes);
  },
  createMembre(membre) {
    return httpClient.post(CHEMINS.membre.replace("/:id", ""), membre);
  },
  destroyMembre(id) {
    return httpClient.delete(CHEMINS.membre.replace(":id", id));
  },
  getMembresParGroupe(idGroupe) {
    return httpClient.get(
      CHEMINS.membresParGroupe.replace(":idGroupe", idGroupe)
    );
  },
  getTache(id) {
    return httpClient.get(CHEMINS.tache.replace(":id", id));
  },
  createTache(tache) {
    return httpClient.post(CHEMINS.tache.replace("/:id", ""), tache);
  },
  updateTache(id, tache) {
    return httpClient.put(CHEMINS.tache.replace(":id", id), tache);
  },
  duplicateTache(id,tache) {
    return httpClient.post(CHEMINS.duplicateTache.replace(":id", id), tache);
  },
  destroyTache(id) {
    return httpClient.delete(CHEMINS.tache.replace(":id", id));
  },
  getTacheParLabel(idLabel) {
    return httpClient.get(CHEMINS.tacheParLabel.replace(":id", idLabel));
  },
  getTacheParProjet(idProjet) {
    return httpClient.get(CHEMINS.tacheParProjet.replace(":id", idProjet));
  },
  getTachesParProjets(listeIdsProjet) {
    return httpClient.get(CHEMINS.tachesParProjets, {
      params: {
        listeIdsProjet: listeIdsProjet.join(","),
      },
    });
  },
  getTachesParRessource(idRessource) {
    return httpClient.get(
      CHEMINS.tachesParRessource.replace(":id", idRessource)
    );
  },
  getTachesPourUneListeDeRessource(listIdRessource) {
    return httpClient.get(
      CHEMINS.tachesPourUneListeDeRessource.replace(":listIdRessource", listIdRessource.join(","))
    );
  },
  createNote(note) {
    return httpClient.post(CHEMINS.note.replace("/:id", ""), note);
  },
  updateNote(id, note) {
    return httpClient.put(CHEMINS.note.replace(":id", id), note);
  },
  destroyNote(id) {
    return httpClient.delete(CHEMINS.note.replace(":id", id));
  },
  createLabel(label) {
    return httpClient.post(CHEMINS.label.replace("/:id", ""), label);
  },
  updateLabel(id, label) {
    return httpClient.put(CHEMINS.label.replace(":id", id), label);
  },
  destroyLabel(id) {
    return httpClient.delete(CHEMINS.label.replace(":id", id));
  },
  getLabels() {
    return httpClient.get(CHEMINS.labels);
  },
  createLienLabel(lienLabel) {
    return httpClient.post(CHEMINS.lienLabel.replace("/:id", ""), lienLabel);
  },
  createTravailleur(travailleur) {
    return httpClient.post(
      CHEMINS.travailleur.replace("/:id", ""),
      travailleur
    );
  },
  destroyTravailleur(id) {
    return httpClient.delete(CHEMINS.travailleur.replace(":id", id));
  },
  getTravailleursParTache(idTache) {
    return httpClient.get(
      CHEMINS.travailleursParTache.replace(":idTache", idTache)
    );
  },
  createSuppleant(suppleant) {
    return httpClient.post(CHEMINS.suppleant.replace("/:id", ""), suppleant);
  },
  destroySuppleant(id) {
    return httpClient.delete(CHEMINS.suppleant.replace(":id", id));
  },
  getSuppleantsParProjet(idProjet) {
    return httpClient.get(
      CHEMINS.suppleantsParProjet.replace(":idProjet", idProjet)
    );
  },
  createPointage(pointage) {
    return httpClient.post(CHEMINS.pointage.replace("/:id", ""), pointage);
  },
  updatePointage(id,pointage) {
    return httpClient.put(CHEMINS.pointage.replace(":id", id), pointage);
  },
  destroyPointage(id) {
    return httpClient.delete(CHEMINS.pointage.replace(":id", id));
  },
  createPointageSpecial(pointageSpecial) {
    return httpClient.post(
      CHEMINS.pointageSpecial.replace("/:id", ""),
      pointageSpecial
    );
  },
  destroyPointageSpecial(id) {
    return httpClient.delete(CHEMINS.pointageSpecial.replace(":id", id));
  },
  getPointagesSpeciaux() {
    return httpClient.get(CHEMINS.pointagesSpeciaux);
  },
  getPointagesParRessource(idRessource) {
    return httpClient.get(
      CHEMINS.pointagesParRessource.replace(":idRessource", idRessource)
    );
  },
  getPointagesParGroupeParPeriode(idGroupe, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.pointagesParGroupeParPeriode
        .replace(":idGroupe", idGroupe)
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getPointagesParProjetsParPeriode(listeProjets, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    // listeProjets tableau [1,2,3]
    return httpClient.get(
      CHEMINS.pointagesParProjetsParPeriode
        .replace(":listeProjets", listeProjets.join(","))
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getPointagesParRessourceParPeriode(idRessource, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.pointagesParRessourceParPeriode
        .replace(":idRessource", idRessource)
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getPointagesPourUneListeDeRessourceParPeriode(ListIdRessource, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.pointagesPourUneListeDeRessourceParPeriode
        .replace(":listIdRessource", ListIdRessource.join(","))
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  createAbsence(absence) {
    return httpClient.post(CHEMINS.absence.replace("/:id", ""), absence);
  },
  updateAbsence(id,absence) {
    return httpClient.put(CHEMINS.absence.replace(":id", id), absence);
  },
  destroyAbsence(id) {
    return httpClient.delete(CHEMINS.absence.replace(":id", id));
  },
  getAbsencesParRessourceParPeriode(idRessource, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.absencesParRessourceParPeriode
        .replace(":idRessource", idRessource)
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getAbsencesPourUneListeDeRessourceParPeriode(ListIdRessource, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.absencesPourUneListeDeRessourceParPeriode
        .replace(":listIdRessource", ListIdRessource.join(","))
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getTypesAbsence(){
    return httpClient.get(CHEMINS.typesAbsence);
  },
  getTempsParTache(idTache) {
    return httpClient.get(CHEMINS.tempsParTache.replace(":idTache", idTache));
  },
  getRepartitionParTache(idTache) {
    return httpClient.get(
      CHEMINS.repartitionParTache.replace(":idTache", idTache)
    );
  },
  getTempsParProjet(idProjet) {
    return httpClient.get(
      CHEMINS.tempsParProjet.replace(":idProjet", idProjet)
    );
  },
  getRepartitionParProjet(idProjet) {
    return httpClient.get(
      CHEMINS.repartitionParProjet.replace(":idProjet", idProjet)
    );
  },
  getProfils(){
    return httpClient.get(CHEMINS.profils.replace("/:idRole/:idEntreprise", ""));
  },
  updateProfil(idRole,idEntreprise,profil){
    return httpClient.put(CHEMINS.profils.replace(":idRole", idRole)
    .replace(":idEntreprise", idEntreprise),profil);
  },
  getExportPointage(idRessource, debut, fin) {
    // debut et fin au format string 'YYYY-MM-DD'
    return httpClient.get(
      CHEMINS.exportPointage
        .replace(":idRessource", idRessource)
        .replace(":debut", debut)
        .replace(":fin", fin)
    );
  },
  getchangementRole(idRessource) {
    return httpClient.get(
      CHEMINS.changementRole
        .replace(":idRessource", idRessource)
    );
  },
};

export default kuberact;
