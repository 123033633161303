import axios from "axios";
import store from "@/store";
import router from "@/router";
import { EventHub } from "@/event-hub";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    timeout: 5000, // ms
    "Content-Type": "application/json; charset=UTF-8",
  },
});

const authInterceptor = (config) => {
  config.headers[
    "Authorization"
  ] = `Bearer ${store.state.utilisateur.tokenJwt}`;
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  (response) => {
    // switch (response.status) {
    //   case 201:
    //   case 204: {
    //     EventHub.$emit("AFFICHER_NOTIFICATION", {
    //       message: "Traitement enregistré",
    //       color: "success",
    //       icon: "mdi-rocket-launch-outline",
    //     });
    //     break;
    //   }
    // }

    return response;
  },
  function(error) {
    if (!error.response) {
      EventHub.$emit("AFFICHER_NOTIFICATION", {
        message: "Réseau ou serveur indisponible",
        color: "warning",
        icon: "mdi-alert",
      });
    } else {
      switch (error.response.status) {
        case 401: {
          EventHub.$emit("AFFICHER_NOTIFICATION", {
            message: "Erreur lors de l'authentification",
            color: "red darken-3",
            icon: "mdi-alert",
          });
          store.dispatch("utilisateur/deconnexion");
          if (router.history.current.fullPath !== "/connexion") {
            router.push("/connexion");
          }
          break;
        }
        case 403: {
          EventHub.$emit("AFFICHER_NOTIFICATION", {
            message: "Droits insuffisants pour réaliser cette action",
            color: "grey",
            icon: "mdi-alert",
          });
          break;
        }
        default: {
          EventHub.$emit("AFFICHER_NOTIFICATION", {
            message: "Opération impossible ou le serveur est indisponible",
            color: "black",
            icon: "mdi-alert",
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
