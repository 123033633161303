export const estConnecte = (state) => !!state.tokenJwt;

export const favoris = (state) => {
  return state.utilisateur.favoris.map((favori) => favori.idProjet);
};

export const nomRole = (state, getters, rootState) => {
  return rootState.entreprise.roles[state.utilisateur.idRole].nomRole;
};

export const estResponsable=(state, getters, rootState) =>  {
  return (
    rootState.entreprise.profils[[state.utilisateur.idEntreprise, state.utilisateur.idRole]]
      .niveauRole <= 10
  );
};

export const estAdministrateur= (state, getters, rootState) =>  {
  return (
    rootState.entreprise.profils[[state.utilisateur.idEntreprise, state.utilisateur.idRole]]
      .niveauRole <= 5
  );
};

// retourn la liste idRessource d'un utilisateur 
export const ListRessourceToRessourceIdList = (ressources) =>{
  return ressources.map(function (ressource) {
    return ressource.idRessource
  });
}

//retourn la liste idEntreprise d'un utilisateur 
export const ListRessourceToEntrepriseIdList = (state) =>{
  return state.utilisateur.ressources.map(function (ressource) {
    return ressource.idEntreprise
  });
}


export const listEntreprise = (state,getters,rootState) =>{
  if(getters.nomRole === "ADMIN")
    return Object.values(rootState.entreprise.entreprises)
      .sort((a, b) => a.nomEntreprise.localeCompare(b.nomEntreprise));
    else
      return Object.values(rootState.entreprise.entreprises)
      .filter((entreprise) =>
        getters.ListRessourceToEntrepriseIdList.includes(entreprise.idEntreprise)
      )
      .sort((a, b) => a.nomEntreprise.localeCompare(b.nomEntreprise));
}
  

//retourne la list de ressource de l'utilisateur selectioner dans la page ressource
export const ressourcesSelectionnee = (state) =>{
  let ressources = state.configuration.ressourcesSelectionnee != null
  ? state.configuration.ressourcesSelectionnee
  : state.utilisateur.ressources;
  return ListRessourceToRessourceIdList(ressources);
}

//retourne la idRessource a utiliser pour le pointage en fontion de l'utilisateur selectionée
//et de la tache selectioner(pour avoir le bonne id ressource correspondean a l'entreprise de la tache ) 
export const idRessourcesSelectionneePourPointage = (state,getters, rootState) => (taches) =>{
  if(state.configuration.itemSelectionnePourPointage==null){
    return null;
  }
  let ressources = state.configuration.ressourcesSelectionnee != null
    ? state.configuration.ressourcesSelectionnee
    : state.utilisateur.ressources;
    let IdRessourceslist = ListRessourceToRessourceIdList(ressources);
  let idEntreprise 
  //si la tache selectionner est une absence il pointer avec la ressourcelier a sont entreprise de base
  //et avec la ressource relative a l'id entreprise du projet au quelle appartien la tache sinon 
  if("nomTypeAbsence" in
  taches[state.configuration.itemSelectionnePourPointage]){
    idEntreprise =  rootState.entreprise.utilisateurs[IdRessourceslist[0]].idEntreprise;
  }
  else{
    idEntreprise = rootState.entreprise.projets[taches[state.configuration.itemSelectionnePourPointage].idProjet].idEntreprise;
  }
  for(const ressource of ressources){
    if(ressource.idEntreprise === idEntreprise){
        return ressource.idRessource;
    }
  }
  return null;
  
}