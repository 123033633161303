<template>
  <div v-show="ouvert">
    <formulaire-projet
      v-if="type === 'projet' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-projet-a-modifier="id"
      @fermer="clearData"
    />
    <formulaire-groupe
      v-if="type === 'groupe' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-groupe-a-modifier="id"
      @fermer="clearData"
    />
    <formulaire-ressource
      v-if="type === 'ressource' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-ressource-a-modifier="id"
      @fermer="clearData"
    />
    <formulaire-tache
      v-if="type === 'tache' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-tache="id"
      :id-projet="data"
      @fermer="clearData"
    />
    <formulaire-note
      v-if="type === 'note' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-note="id"
      @fermer="clearData"
    />
    <formulaire-export-pointages
      v-if="type === 'export-pointages' && ouvert"
      :nom-page="type + '_formulaire'"
      :ressources="id"
      :data="data"
      @fermer="clearData"
    />
    <formulaire-export-pointages-excel
      v-if="type === 'export-pointages-excel' && ouvert"
      :nom-page="type + '_formulaire'"
      :ressources="id"
      :data="data"
      @fermer="clearData"
    />
    <formulaire-export-projet
      v-if="type === 'export-projet' && ouvert"
      :nom-page="type + '_formulaire'"
      :id-projet="id"
      :data="data"
      @fermer="clearData"
    />
    <formulaire-utilisateur
      v-if="type === 'utilisateur' && ouvert"
      :nom-page="type + '_formulaire'"
      @fermer="clearData"
    />
  </div>
</template>
<script>
import FormulaireProjet from "@/components/forms/FormulaireProjet";
import FormulaireGroupe from "@/components/forms/FormulaireGroupe";
import FormulaireRessource from "@/components/forms/FormulaireRessource";
import FormulaireTache from "@/components/forms/FormulaireTache";
import FormulaireNote from "@/components/forms/FormulaireNote";
import FormulaireExportPointages from "@/components/forms/FormulaireExportPointages";
import FormulaireExportPointagesExcel from "@/components/forms/FormulaireExportPointagesExcel";
import FormulaireExportProjet from "@/components/forms/FormulaireExportProjet";
import FormulaireUtilisateur from "@/components/forms/FormulaireUtilisateur";
import { EventHub } from "@/event-hub.js";

export default {
  components: {
    FormulaireProjet,
    FormulaireGroupe,
    FormulaireRessource,
    FormulaireTache,
    FormulaireNote,
    FormulaireExportPointages,
    FormulaireExportPointagesExcel,
    FormulaireExportProjet,
    FormulaireUtilisateur,
  },
  data: () => ({
    ouvert: false,
    type: null,
    // if id is 0, then we want to create and not edit
    id: null,
    data: null,
    //
  }),
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    // Last message to arrive wins !
    EventHub.$on("AFFICHER_FORMULAIRE", (payload) => {
      // config : {type: String, id: Number}
      const TYPES = [
        "projet",
        "tache",
        "ressource",
        "groupe",
        "note",
        "export-pointages",
        "export-pointages-excel",
        "export-projet",
        "utilisateur",
      ];
      if (TYPES.includes(payload.type)) {
        if (payload.type === "tache") {
          this.data = payload.idProjetPourTache;
        } else if (payload.type === "export-pointages") {
          // || payload.type === "export-pointages-excel"
          this.data = payload.data;
        } else if (payload.type === "export-pointages-excel") {
          this.data = payload.data;
        } else if (payload.type === "export-projet") {
          this.data = payload.data;
        }
        this.ouvert = true;
        this.type = payload.type;
        this.id = payload.id;
      } else {
        console.log(
          "wrong inputs when trying to display a form",
          payload.type,
          payload.id
        );
      }
    });
  },
  mounted() {
    //
  },
  methods: {
    clearData() {
      this.ouvert = false;
      this.type = null;
      this.id = null;
      this.data = null;
    },
  },
};
</script>
<style scoped></style>
