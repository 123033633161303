<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Projets).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      :titre="`${editionMode ? 'Modifier' : 'Créer'} une note`"
      :nom-page="nomPage"
      :supprimable="editionMode"
      :loading="loading"
      :afficher-message-champs-obligatoire="false"
      :saving="saving"
      @sauvegarder="sauvegarderNote"
      @supprimer="supprimerNote"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12" sm="12">
          <v-textarea
            v-model="note.commentaire"
            autofocus
            rows="10"
            solo
            clearable
            no-resize
            :rules="[rules.nonVide]"
            hide-details
            label="Note*"
            required
          ></v-textarea>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapGetters, mapState } from "vuex";
import Api from "@/api/kuberact";
import RULES from "@/utils/enums";

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    idNote: {
      type: Number,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    note: {},
    rules: RULES,
  }),
  computed: {
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("entreprise", ["getIdRessourceByTache"]),
    editionMode: function() {
      return this.idNote !== null ? this.idNote > 0 : null;
    },
  },
  watch: {
    idNote: function() {
      this.loading = true;
      if (this.editionMode) {
        this.note = {
          ...this.configuration.tacheSelectionnee.notes.find(
            (note) => note.idNote === this.idNote
          ),
        };
      } else {
        this.note.idTache = this.configuration.tacheSelectionnee.idTache;
        this.note.idRessource = this.utilisateur.idRessource;
      }
      this.loading = false;
    },
  },
  created() {
    if (this.editionMode) {
      this.note = {
        ...this.configuration.tacheSelectionnee.notes.find(
          (note) => note.idNote === this.idNote
        ),
      };
    } else {
      this.note.idTache = this.configuration.tacheSelectionnee.idTache;
      this.note.idRessource = this.getIdRessourceByTache(
        this.utilisateur,
        this.configuration.tacheSelectionnee
      );
    }
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", ["recupererTacheSelectionnee"]),
    clearData() {
      this.note = {};
    },
    supprimerNote() {
      this.loading = true;
      Api.destroyNote(this.idNote)
        .then(() => {
          this.recupererTacheSelectionnee();
          this.$emit("fermer");
          this.clearData();
        })
        .finally(() => (this.loading = false));
    },
    sauvegarderNote() {
      this.saving = true;
      const processNote = this.editionMode ? Api.updateNote : Api.createNote;
      const args = this.editionMode ? [this.idNote, this.note] : [this.note];
      processNote(...args)
        .then(async () => {
          // TODO: remove la récupération et faire l'injection de la réponse dans le store
          await this.recupererTacheSelectionnee();
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
