<template>
  <v-container fluid fill-height pa-0 class="background-image">
    <v-row no-gutters class="align-center justify-center">
      <v-card id="card-back" class="elevation-12" :loading="loading">
        <v-card-text class="text-center">
          <img id="logo" src="../assets/time_keeper_light_with_text.svg" />
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent="authentifierLUtilisateur"
            @keyup.enter="authentifierLUtilisateur"
          >
            <v-text-field
              id="login-text-field-email"
              v-model="email"
              autofocus
              label="E-mail"
              maxlength="50"
              :rules="[rules.email, rules.nonVide]"
              prepend-icon="mdi-account-outline"
              required
            ></v-text-field>
            <v-text-field
              id="login-text-field-password"
              v-model="password"
              label="Mot de passe"
              maxlength="50"
              :rules="[rules.nonVide]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock-outline"
              required
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-card-actions
              ><v-btn
                id="login-btn-s-identifier"
                :disabled="!isFormValid"
                dark
                type="submit"
              >
                S'identifier
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
// import { loginService } from "@/services";
// import { storage } from "@/api-services";
// import router from "@/router";
// import { mapState, mapActions } from "vuex";
//
import { mapActions } from "vuex";
import RULES from "@/utils/enums";
import { EventHub } from "@/event-hub";

export default {
  data: () => ({
    loading: false,
    isFormValid: false,
    showPassword: false,
    rules: RULES,
    email: "",
    password: "",
  }),
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", ["connexion", "deconnexion"]),
    ...mapActions("entreprise", [
      "recupererUtilisateurs",
      "recupererRoles",
      "recupererProjets",
      "recupererEntreprises",
      "recupererProfils",
      "recupererGroupes",
    ]),
    authentifierLUtilisateur: function() {
      if (this.isFormValid) {
        let email = this.email;
        let password = this.password;
        this.connexion({ email, password })
          .then(async () => {
            this.loading = true;
            await Promise.all([
              this.recupererUtilisateurs(),
              this.recupererRoles(),
              this.recupererProjets(),
              this.recupererEntreprises(),
              this.recupererProfils(),
            ])
              .then(() => {
                this.$router.push("/");
              })
              .catch((err) => {
                console.log(err);
                EventHub.$emit("AFFICHER_NOTIFICATION", {
                  message: "Impossible de charger le contexte",
                  color: "black",
                  icon: "mdi-alert",
                });
                this.deconnexion();
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch((err) => {
            console.log(err);
            EventHub.$emit("AFFICHER_NOTIFICATION", {
              message: "E-mail ou mot de passe incorrect",
              color: "red darken-3",
              icon: "mdi-emoticon-sad-outline",
            });
          });
      }
    },
  },
};
</script>
<style scoped>
.background-image {
  background-image: url("../assets/background-login.jpg");
  background-size: cover;
}
#logo {
  /* max-height: 40%; */
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  /* padding-top: ; */
}
#card-back {
  width: 75%;
  max-width: 400px;
  max-height: 90%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(233, 233, 233, 0.8) 100%
  );
}
#login-btn-s-identifier {
  background-color: #004087;
  padding-left: 25%;
  padding-right: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}
</style>
