<template>
  <div>
    <formulaire-pop-up
      titre="Feuille de pointages"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"
      :saving="saving"
      :afficher-message-champs-obligatoire="false"
      @sauvegarder="exportExcel()"
      v-on="$listeners"
    >
      <template #champs="">
        <v-row>
          <v-col class="text-center body-1" cols="12">{{ data.title }}</v-col>
          <v-col cols="12">
            <table
              id="tableau-pointages"
              style="width: 100%"
              class="tab-pointage ma-0"
            >
              <thead class="thead-style">
                <tr>
                  <th width="100">Matricule SAP</th>
                  <th width="150">Jour</th>
                  <th>Technique</th>
                  <th width="150">Affaire</th>
                  <th>Catégorie absence / présence</th>
                  <th>Rubrique</th>
                  <th width="60">Nb jour</th>
                </tr>
              </thead>
              <tbody class="tbody-style">
                <tr
                  v-for="(ligne, index) in feuilleDePointage"
                  :key="index"
                  :class="ligne.type"
                >
                  <td format="String" type="String">{{ ligne.matricule }}</td>
                  <td>{{ ligne.jour }}</td>
                  <td> </td>
                  <td>{{ ligne.numeroDAffaire }}</td>
                  <td>{{ ligne.categorieAbsencePresence }}</td>
                  <td> </td>
                  <td class="text-right">
                    {{ (Math.round(ligne.nbJour * 100) / 100)*ligne.tempsTravaille }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapState, mapGetters } from "vuex";
import RULES from "@/utils/enums";
import Api from "@/api/kuberact";
import { heureFormaterToMinutes } from "@/utils/transform";
import xlsx from "xlsx";
export default {
  components: {
    FormulairePopUp,
  },
  props: {
    ressources: {
      type: Array,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    saving: false,
    rules: RULES,
    pointages: {},
  }),
  computed: {
    ...mapState("entreprise", ["entreprises", "utilisateurs", "projets", "profils"]),
    ...mapGetters("entreprise", ["getRole"]),

    feuilleDePointage: function() {
      let tableauPointages = [];
      const date = new Date(this.data["date"]);
      const nombreDeJoursDansLeMois = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      const numeroAnneeFormate = date.getFullYear(); // YYYY
      const numeroMoisFormate = (date.getMonth() + 1 + "").padStart(2, "0"); // padding '01' pour janvier
      for (
        let numeroJour = 1;
        numeroJour <= nombreDeJoursDansLeMois;
        numeroJour++
      ) {
        const numeroJourFormate = (numeroJour + "").padStart(2, "0"); //padding '01' pour le 1er
        const cleElementCalendrier = `${numeroAnneeFormate}-${numeroMoisFormate}-${numeroJourFormate}`;
        const dateElement = new Date(cleElementCalendrier);
        if (dateElement.getDay() == 6 || dateElement.getDay() == 0 || 
          !(cleElementCalendrier in this.pointages) ) {
          // Weekend || pas de pointage ce jour
        } else {     
            const listePointageDuJour = this.pointages[
              `${numeroAnneeFormate}-${numeroMoisFormate}-${numeroJourFormate}`
            ];
            let ligneTableauPointage = {};
            for (let j = 0; j < listePointageDuJour.length; j++) {
              const idProjet = listePointageDuJour[j]["id_projet"];
                ligneTableauPointage = {
                  ressource: listePointageDuJour[j]["id_ressource"],
                  matricule: `'${this.utilisateurs[listePointageDuJour[j]["id_ressource"]].matricule}`,
                  jour: `${numeroMoisFormate}/${numeroJourFormate}/${numeroAnneeFormate}`,
                  numeroDAffaire: isNaN(idProjet)
                  ? "'"
                  : `'${this.projets[idProjet].numAffaire}`,
                  nbJour: listePointageDuJour[j]["nb_jour"],
                  tempsTravaille: this.profils[[this.data["entreprise"], 
                    this.utilisateurs[listePointageDuJour[j]["id_ressource"]].idRole]].tempsTravaille,
                  categorieAbsencePresence: `'${this.profils[[this.data["entreprise"], 
                    this.utilisateurs[listePointageDuJour[j]["id_ressource"]].idRole]].categorieAbsencePresence}`,
                };
                tableauPointages.push(ligneTableauPointage);
          };
        };
      };
      function compare(a, b) { //tri du tableau par ressource
        if (a.ressource < b.ressource)
          return -1;
        if (a.ressource > b.ressource)
          return 1;
        return 0;
      }
      return tableauPointages.sort(compare);
    }, // fin feuilleDePointage

  },
  watch: {
    //
  },
  created() {
    this.getExportPointage();
  },
  mounted() {
    //
  },
  methods: {
    heureFormaterToMinutes: heureFormaterToMinutes,
    getExportPointage() {
      this.loading = true;
      const date = new Date(this.data["date"]);
      const nombreDeJoursDansLeMois = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      const anneeMoisISO = date.toISOString().slice(0, 7); // YYYY-MM
      const debut = `${anneeMoisISO}-01`;
      const fin = `${anneeMoisISO}-${nombreDeJoursDansLeMois}`;
      let listidRessource = this.data["listeIdRessources"].map(function(ressource) {
        return ressource.idRessource;
      });

      Api.getExportPointage(listidRessource, debut, fin)
        .then((response) => {
          this.pointages = response.data;
          let ListRessourceId = [];
          for (const day in this.pointages) {
            for (const pointage in this.pointages[day]) {
              if (
                !ListRessourceId.includes(
                  this.pointages[day][pointage]["id_ressource"]
                ) &&
                this.pointages[day][pointage]["id_projet"] in this.projets
              )
                ListRessourceId.push(
                  this.pointages[day][pointage]["id_ressource"]
                ); //fin if
            }
          }
        }) // fin then
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    }, // fin get export pointage

    clearData() {
      this.data = {};
    },

    //excel
    exportExcel(){
      this.saving = true;
      try {
        const XLSX = xlsx;
        const nomfichier = `Pointage ${this.data.title}.xlsx`;
        const elt = document.getElementById('tableau-pointages');
        const wb = XLSX.utils.table_to_book(elt, {sheet:"exportPointage"});

	  	  XLSX.writeFile(wb, nomfichier);
        this.$emit("fermer");

      } catch (err) {
        console.log(err);
      } finally {
        this.saving = false;
      }     

    }, //fin exportExcel
  },
};
</script>

<style scoped>
table {
  border-spacing: 0px;
}

.thead-style th,
.tbody-style td,
.tfoot-style td {
  padding: 5px;
}
.thead-style {
  background-color: #004087;
  border: 1px solid #004087;
  color: #fff;
}
.thead-style th {
  text-align: center;
}
.tbody-style td,
.tfoot-style td {
  border: 2px solid #cccccc;
}
.weekend {
  background-color: #dadada;
}
.tfoot-style {
  font-weight: bold;
}
.tfoot-absences {
  background-color: #ffba8c;
  color: black;
}
.tfoot-travail {
  background-color: #004087;
  color: white;
}
.manque {
  background-color: #ff9999;
}
.autre_entreprise {
  background-color: #bed3ce;
}
</style>
