<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Ressources).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      :titre="`${editionMode ? 'Modifier' : 'Créer'} une ressource`"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"
      :saving="saving"
      @sauvegarder="sauvegarderRessource"
      @fermer="clearData"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="ressourceAModifier.nom"
            :test-auto="nomPage + '_nom'"
            autofocus
            :rules="[rules.nonVide]"
            hide-details
            maxlength="50"
            label="Nom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="ressourceAModifier.prenom"
            :test-auto="nomPage + '_prenom'"
            :rules="[rules.nonVide]"
            hide-details
            maxlength="50"
            label="Prenom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="ressourceAModifier.mail"
            :test-auto="nomPage + '_email'"
            :rules="[rules.email, rules.nonVide]"
            maxlength="50"
            label="E-mail*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            v-model="ressourceAModifier.idRole"
            :test-auto="nomPage + '_role'"
            label="Rôle*"
            required
            :items="
              Object.values(roles).sort((a, b) =>
                a.nomRole.localeCompare(b.nomRole)
              )
            "
            item-text="nomRole"
            item-value="idRole"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-switch
            v-model="ressourceAModifier.actif"
            :test-auto="nomPage + '_actif'"
            inset
            color="green"
            :label="
              ressourceAModifier.actif ? 'Compte activé' : 'Compte désactivé'
            "
          ></v-switch>
        </v-col>
        <v-col v-if="nomRole === 'ADMIN' || nomRole === 'ADMIN_ENT'" cols="12">
          <v-select
            v-model="ressourceAModifier.idEntreprise"
            :test-auto="nomPage + '_entreprise'"
            hide-details
            label="Entreprise*"
            required
            :items="
              Object.values(entreprises).sort((a, b) =>
                a.nomEntreprise.localeCompare(b.nomEntreprise)
              )
            "
            item-text="nomEntreprise"
            item-value="idEntreprise"
          ></v-select>
        </v-col>

        <v-col
          v-if="(nomRole === 'ADMIN' || nomRole === 'ADMIN_ENT') && editionMode"
          cols="12"
        >
          <v-select
            v-model="ListEntrepiseMailler"
            :test-auto="nomPage + '_maillage'"
            hide-details
            label="Maillage*"
            multiple
            persistent-hint
            :items="
              Object.values(entreprises)
                .filter(
                  (entreprise) =>
                    entreprise.idEntreprise != ressourceAModifier.idEntreprise
                )
                .sort((a, b) => a.nomEntreprise.localeCompare(b.nomEntreprise))
            "
            item-text="nomEntreprise"
            item-value="idEntreprise"
          ></v-select>
        </v-col>

        <v-col class="" cols="12">
          <v-divider></v-divider>

          <div class="body-1 text-center font-weight-medium pt-2 mb-n4">
            {{ editionMode ? "Changer" : "Définir" }} le mot de passe
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="nouveauMdp"
            :test-auto="nomPage + '_mdp'"
            :append-icon="afficherMdp ? 'mdi-eye' : 'mdi-eye-off'"
            :type="afficherMdp ? 'text' : 'password'"
            :rules="[rules.mdpCompliqueOuVide]"
            :label="`Nouveau mot de passe${editionMode ? '' : '*'}`"
            required
            @click:append="afficherMdp = !afficherMdp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="nouveauMdpConfirmation"
            :test-auto="nomPage + '_mdpConfirmer'"
            :append-icon="afficherMdp ? 'mdi-eye' : 'mdi-eye-off'"
            :type="afficherMdp ? 'text' : 'password'"
            :rules="passwordRules"
            :label="`Confirmer le mot de passe${editionMode ? '' : '*'}`"
            required
            @click:append="afficherMdp = !afficherMdp"
          ></v-text-field>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapGetters, mapState } from "vuex";
import RULES from "@/utils/enums";
import Api from "@/api/kuberact";

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    idRessourceAModifier: {
      type: Number,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    ressourceAModifier: {},
    ListEntrepiseMailler: [],
    afficherMdp: false,
    rules: RULES,
    nouveauMdp: "",
    nouveauMdpConfirmation: "",
    //
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "roles", "entreprises"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", ["nomRole"]),
    editionMode: function() {
      return this.idRessourceAModifier !== null
        ? this.idRessourceAModifier > 0
        : null;
    },
    passwordRules: function() {
      let customRules = [
        this.rules.mdpCompliqueOuVide,
        Object.is(this.nouveauMdp, this.nouveauMdpConfirmation) ||
          "Les mots de passe doivent être identiques",
      ];
      if (!this.editionMode) {
        // Lors de la création il faut un mot de passe
        customRules.push(this.rules.nonVide);
      }
      return customRules;
    },
    //
  },
  watch: {
    idRessourceAModifier: function(newidRessourceAModifier) {
      this.recupererLaRessource(newidRessourceAModifier);
    },
    //
  },
  created() {
    this.recupererLaRessource(this.idRessourceAModifier);
    this.loading = false;
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["createUtilisateur", "updateUtilisateur"]),
    clearData() {
      this.ressourceAModifier = {};
      this.afficherMdp = false;
      this.nouveauMdp = "";
      this.nouveauMdpConfirmation = "";
    },
    sauvegarderRessource() {
      let ListEntrepiseMaillerfinal = [];

      if (
        !this.ListEntrepiseMailler.filter(
          (e) => e.idEntreprise === this.ressourceAModifier.idEntreprise
        ).length > 0
      ) {
        ListEntrepiseMaillerfinal.push({
          idEntreprise: this.ressourceAModifier.idEntreprise,
        });
      }

      for (let index = 0; index < this.ListEntrepiseMailler.length; index++) {
        ListEntrepiseMaillerfinal.push({
          idEntreprise: this.ListEntrepiseMailler[index],
        });
      }

      this.ressourceAModifier.ressources = ListEntrepiseMaillerfinal;
      this.saving = true;
      if (
        !this.editionMode ||
        (this.editionMode && this.nouveauMdp !== null && this.nouveauMdp.length)
      ) {
        this.ressourceAModifier.password = this.nouveauMdp;
      }

      const processUtilisateur = this.editionMode
        ? this.updateUtilisateur
        : this.createUtilisateur;

      processUtilisateur(this.ressourceAModifier)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    recupererLaRessource(idRessource) {
      if (idRessource !== null) {
        this.ressourceAModifier = {};
        if (!this.editionMode) {
          // Default value on element creation
          this.ressourceAModifier = {
            idRole: 5,
            actif: true,
            idEntreprise: this.utilisateur.idEntreprise,
          };
        } else if (this.editionMode && idRessource in this.utilisateurs) {
          // Copy only what's needed here
          Api.getUtilisateur(this.utilisateurs[idRessource].idUtilisateur).then(
            (response) => {
              let utilisateur = response.data;
              this.ListEntrepiseMailler = utilisateur.ressources
                .filter((ressource) => ressource.actif)
                .map(function(ressource) {
                  return ressource.idEntreprise;
                });
              const ressourceEnMemoire = { ...this.utilisateurs[idRessource] };
              this.ressourceAModifier = {
                idEntreprise: ressourceEnMemoire.idEntreprise,
                idUtilisateur: ressourceEnMemoire.idUtilisateur,
                ressources: ressourceEnMemoire.ressources,
                idRole: ressourceEnMemoire.idRole,
                prenom: ressourceEnMemoire.prenom,
                actif: ressourceEnMemoire.actif,
                mail: ressourceEnMemoire.mail,
                nom: ressourceEnMemoire.nom,
              };
            }
          );
        } else {
          this.$emit("fermer");
          this.clearData();
        }
      }
    },
    //
  },
};
</script>

<style scoped></style>
