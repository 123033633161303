import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Connexion from "@/pages/Connexion";
import ENUMS from "@/utils/enums";

Vue.use(VueRouter);

const routes = [
  {
    path: "/connexion",
    name: "Connexion",
    component: Connexion,
    meta: {
      isPublicPage: true,
    },
  },
  {
    path: "/",
    name: "Accueil",
    // route level code-splitting
    // this generates a separate chunk (accueil.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "accueil" */ "@/pages/Accueil"),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/entreprises",
    name: "Entreprises",
    // route level code-splitting
    // this generates a separate chunk (Entreprise.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Entreprise" */ "@/pages/Entreprises"),
    meta: {
      autorisation: ENUMS.roleAdministateur,
    },
  },
  {
    path: "/groupes",
    name: "Groupes",
    // route level code-splitting
    // this generates a separate chunk (groupes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "groupes" */ "@/pages/Groupes"),
    meta: {
      autorisation: ENUMS.rolesManager,
    },
  },
  {
    path: "/pointages",
    name: "Pointages",
    component: () =>
      import(/* webpackChunkName: "pointages" */ "@/pages/Pointages"),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/pointages/projets",
    name: "Pointages projets",
    component: () =>
      import(
        /* webpackChunkName: "pointages-projets" */ "@/pages/PointagesProjets"
      ),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/pointages/groupe",
    name: "Pointages groupe",
    component: () =>
      import(
        /* webpackChunkName: "pointages-groupe" */ "@/pages/PointagesGroupe"
      ),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/projets",
    name: "Projets",
    component: () =>
      import(/* webpackChunkName: "projets" */ "@/pages/Projets"),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/ressources",
    name: "Ressources",
    component: () =>
      import(/* webpackChunkName: "ressources" */ "@/pages/Ressources"),
    meta: {
      autorisation: ENUMS.rolesManager,
    },
  },
  {
    path: "/statistiques/:type/:id",
    name: "Statistiques",
    component: () =>
      import(/* webpackChunkName: "statistiques" */ "@/views/Statistiques"),
    meta: {
      autorisation: ENUMS.rolesUtilisateur,
    },
  },
  {
    path: "/export",
    name: "Export",
    component: () =>
      import(/* webpackChunkName: "export" */ "@/pages/Export"),
    meta: {
      autorisation: ENUMS.roleAdministateur,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isPublicPage)) {
    next();
  } else {
    if (store.getters["utilisateur/estConnecte"]) {
      // TODO: faire l'étape d'autorisation
      // if ( record.meta.autorisation.includes(store.getter[entreprise/getRoleById]) ) {
      //   next()
      // }
      next();
      // next(false);
      return;
    }
    next("/connexion");
  }
});

export default router;
