<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Projets).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      :titre="`${editionMode ? 'Modifier' : 'Créer'} un groupe`"
      :nom-page="nomPage"
      :supprimable="editionMode"
      :loading="loading"
      :saving="saving"
      @sauvegarder="sauvegarderGroupe"
      @supprimer="supprimerGroupe"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12">
          <v-text-field
            v-model="groupeAModifier.nom"
            :test-auto="nomPage + '_nom'"
            :rules="[rules.nonVide]"
            autofocus
            hide-details
            maxlength="150"
            label="Nom*"
            required
          ></v-text-field>
        </v-col>
        <v-col v-if="listEntreprise.length > 1" cols="12">
          <v-select
            v-model="idEntreprise"
            :test-auto="nomPage + '_entreprise'"
            hide-details
            label="Entreprise*"
            required
            :items="listEntreprise"
            item-text="nomEntreprise"
            item-value="idEntreprise"
            @change="clearResponsableAndMembre()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="9">
          <v-autocomplete
            v-model="groupeAModifier.idRessource"
            :test-auto="nomPage + '_responsable'"
            label="Responsable*"
            required
            hide-details
            :items="listeUtilisateursCompleteResponsable"
            item-text="labelUtilisateur"
            item-value="idRessource"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-switch
            v-model="groupeAModifier.public"
            :test-auto="nomPage + '_public'"
            inset
            hide-details
            color="green"
            :label="groupeAModifier.public ? 'Groupe public' : 'Groupe privé'"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12">
          <v-autocomplete
            v-model="groupeAModifier.listeIdsRessourceMembre"
            :test-auto="nomPage + '_membres'"
            :loading="loadingMembres"
            :disabled="loadingMembres"
            label="Membres"
            required
            hide-details
            :items="listeUtilisateursComplete"
            item-text="labelUtilisateur"
            item-value="idRessource"
            :search-input.sync="searchInputGroupes"
            multiple
            clearable
            chips
            auto-select-first
            deletable-chips
            dense
            @change="searchInputGroupes = ''"
          ></v-autocomplete>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapGetters, mapState } from "vuex";
import Api from "@/api/kuberact";
import ENUMS from "@/utils/enums";

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    idGroupeAModifier: {
      type: Number,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    loadingMembres: false,
    saving: false,
    groupeAModifier: {},
    rules: ENUMS,
    searchInputGroupes: "",
    idEntreprise: null,
  }),
  computed: {
    ...mapState("entreprise", [
      "utilisateurs",
      "entreprises",
      "groupes",
      "roles",
    ]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", [
      "nomRole",
      "ListRessourceToEntrepriseIdList",
      "listEntreprise",
    ]),
    ...mapGetters("entreprise", [
      "utilisateurToidRessourceByIdEntreprise",
      "getEntreprisebyIdRessource",
    ]),
    editionMode: function() {
      return this.idGroupeAModifier !== null
        ? this.idGroupeAModifier > 0
        : null;
    },
    listeUtilisateursComplete: function() {
      return Object.values(this.utilisateurs)
        .filter(
          (utilisateur) =>
            utilisateur.actif &&
            this.utilisateurToidRessourceByIdEntreprise(
              utilisateur,
              this.idEntreprise
            ) != -1
        )
        .map((utilisateur) => ({
          idRole: utilisateur.idRole,
          idRessource: this.utilisateurToidRessourceByIdEntreprise(
            utilisateur,
            this.idEntreprise
          ),
          labelUtilisateur: `${utilisateur.prenom} ${utilisateur.nom}`,
        }))
        .sort((a, b) => a.labelUtilisateur.localeCompare(b.labelUtilisateur));
    },
    listeUtilisateursCompleteResponsable: function() {
      return this.listeUtilisateursComplete.filter((utilisateur) =>
        ENUMS.rolesManager.includes(this.roles[utilisateur.idRole].nomRole)
      );
    },
    //
  },
  watch: {
    idGroupeAModifier: function(newidGroupeAModifier) {
      this.loading = true;
      this.recupererLeGroupe(newidGroupeAModifier).finally(
        () => (this.loading = false)
      );
    },
    //
  },
  created() {
    if (Object.values(this.ListRessourceToEntrepriseIdList).length == 1) {
      this.idEntreprise = this.utilisateur.idEntreprise;
    }
    console.log(this.idEntreprise);
    this.recupererLeGroupe(this.idGroupeAModifier)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", [
      "createGroupe",
      "updateGroupe",
      "deleteGroupe",
    ]),
    clearData() {
      this.groupeAModifier = {};
    },
    supprimerGroupe() {
      this.loading = true;
      this.deleteGroupe(this.idGroupeAModifier)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .finally(() => (this.loading = false));
    },
    sauvegarderGroupe() {
      this.saving = true;
      const processGroupe = this.editionMode
        ? this.updateGroupe
        : this.createGroupe;
      processGroupe(this.groupeAModifier)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    recupererLeGroupe(idGroupe) {
      if (idGroupe !== null) {
        this.groupeAModifier = {};
        if (!this.editionMode) {
          // Default value on element creation
          this.groupeAModifier = {
            public: false,
            idRessource: this.utilisateur.ressources[0].idRessource,
            listeIdsRessourceMembre: [],
            listeMembresInitiale: [],
          };
          return Promise.resolve();
        } else if (this.editionMode && idGroupe in this.groupes) {
          // Récupérer les suppléants
          this.loadingMembres = true;
          // Copy only what's needed here
          const groupeEnMemoire = { ...this.groupes[idGroupe] };
          this.groupeAModifier = {
            idRessource: groupeEnMemoire.idRessource,
            idGroupe: groupeEnMemoire.idGroupe,
            public: groupeEnMemoire.public,
            nom: groupeEnMemoire.nom,
          };
          this.idEntreprise = this.getEntreprisebyIdRessource(
            groupeEnMemoire.idRessource
          );
          return Api.getMembresParGroupe(idGroupe)
            .then((response) => {
              this.groupeAModifier.listeIdsRessourceMembre = response.data.map(
                (membre) => membre.idRessource
              );
              this.groupeAModifier.listeMembresInitiale = response.data;
            })
            .catch((err) => {
              console.log(err);
              this.$emit("fermer");
              this.clearData();
              return Promise.reject();
            })
            .finally(() => (this.loadingMembres = false));
        } else {
          this.$emit("fermer");
          this.clearData();
          return Promise.reject();
        }
      } else {
        return Promise.resolve();
      }
    },
    clearResponsableAndMembre() {
      this.groupeAModifier.idRessource = this.utilisateur.ressources[0].idRessource;
      this.groupeAModifier.listeIdsRessourceMembre = [];
    },
  },
};
</script>

<style scoped></style>
