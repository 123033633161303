import Vue from "vue";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import VueDragDrop from "vue-drag-drop";

Vue.use(VueDragDrop);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
