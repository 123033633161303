export default () => ({
  utilisateurs: {}, // Ordonné par idRessource cf. mutation
  entreprises: {},
  projets: {},
  roles: {},
  detailsProjets: {}, // {idProjet: {idProjet, filles:[{tacheMere}, ...], tempsPrevuCumule: 0, tempsPointeCumule: 0, tempsResteAFaireCumule: 0}}
  groupes: {},
  profils:{},
  configuration: {
    entrepriseSelectionnee: null, 
    utilisateursByEntrepriseSelectionnee: {},
  },
});
