<template>
  <div class="text-center">
    <v-snackbar
      ref="banner"
      v-model="config.notification"
      :color="config.color"
      max-width="500px"
      top
      center
      :timeout="config.timeout"
    >
      <v-row no-gutters class="flex-nowrap align-center">
        <v-icon
          v-if="config.icon"
          class="mr-2"
          :color="config.iconColor"
          v-text="config.icon"
        />
        <span class="mr-auto">{{ config.message }}</span>
        <v-icon @click="closeSnack()">mdi-close</v-icon>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { EventHub } from "@/event-hub.js";

export default {
  data: () => ({
    defaultConfig: {
      notification: false,
      timeout: 2500,
      color: "grey",
      icon: null,
      iconColor: "white",
      message: "",
    },
    config: {},
  }),
  mounted() {
    // Last message to arrive wins !
    EventHub.$on("AFFICHER_NOTIFICATION", (customConfig) => {
      if (customConfig.message) {
        // Using Object.assign({}, ...) to trigger reactivity
        this.config = Object.assign({}, this.defaultConfig, customConfig, {
          notification: true,
        });
      } else {
        console.log(
          "'message' key is required when diplaying a notification.",
          customConfig
        );
      }
    });
  },
  methods: {
    closeSnack() {
      this.config.notification = false;
    },
  },
};
</script>
