<template>
  <v-app>
    <!-- Sizes your content based upon application components -->

    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="list-item-avatar, divider, table-heading, list-item-two-line, image"
    ></v-skeleton-loader>
    <!-- type="actions,article,avatar,card,card-avatar,card-heading,chip,date-picker,date-picker-options,date-picker-days,heading,image,list-item,list-item-avatar,list-item-two-line, list-item-avatar-two-line,list-item-three-line,list-item-avatar-three-line,paragraph,sentences,table,text" -->

    <barre-de-navigation
      v-if="$route.name !== 'Connexion' && !loading && estConnecte"
    />

    <notification />

    <formulaires />

    <v-main v-if="!loading">
      <!-- Provides the application the proper gutter -->
      <v-container fluid fill-height pa-0>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import BarreDeNavigation from "@/components/BarreDeNavigation";
import Notification from "@/components/utils/Notification";
import Formulaires from "@/components/Formulaires";
import { mapActions, mapGetters } from "vuex";
import { EventHub } from "@/event-hub";

export default {
  name: "App",
  components: {
    BarreDeNavigation,
    Notification,
    Formulaires,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("utilisateur", ["estConnecte"]),
  },
  async created() {
    if (this.estConnecte) {
      // contexte: utilisateur déjà connecté (token dans le localStorage),
      // il revient sur l'application et on doit reconstruire de store de zéro
      // On importe donc les données nécessaire au fonctionnement global
      await this.rechargerUtilisateur()
        .then(async () => {
          await Promise.all([
            this.recupererUtilisateurs(),
            this.recupererRoles(),
            this.recupererProjets(),
            this.recupererEntreprises(),
            this.recupererProfils(),
          ]).catch((err) => {
            console.log(err);
            EventHub.$emit("AFFICHER_NOTIFICATION", {
              message: "Impossible de charger le contexte",
              color: "black",
              icon: "mdi-alert",
            });
            this.deconnexion();
          });
        })
        .catch((err) => {
          console.log(err);
          EventHub.$emit("AFFICHER_NOTIFICATION", {
            message: "Session expirée ! Il est temps de se reconnecter",
            timeout: 5000,
            icon: "mdi-information-outline",
          });
          this.deconnexion();
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  mounted() {
    //
  },
  methods: {
    // TODO: replace login with just a get on /utilisateur/{id}
    ...mapActions("utilisateur", ["rechargerUtilisateur", "deconnexion"]),
    ...mapActions("entreprise", [
      "recupererUtilisateurs",
      "recupererRoles",
      "recupererProjets",
      "recupererEntreprises",
      "recupererGroupes",
      "recupererProfils",
    ]),
    async chargerLeContexte() {},
  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'

html
  overflow-y: auto

/* conteneur-page, heritage-scroll, scrollable-content
 permettent de gérer le scroll dans les composant sans overflow global
 */

.conteneur-page
  /* background: #3F51B5; */
  /* give the outermost container a predefined size */
  position: absolute
  top: 0
  bottom: 0
  left: 0
  width: 100%
  display: flex
  flex-direction: column

.heritage-scroll
  min-height: 0px
  display: flex
  /* flex-direction: column; */

.scrollable-content
  flex-grow: 1
  overflow-y: auto
  /* -webkit-overflow-scrolling: touch; */
  /* for Firefox */
  min-height: 0

.gradient-bleu
  background-image: linear-gradient(to right top, #004087, #0071b1, #009da0)
  /* background-image: linear-gradient(to right top, #30cfd0, #330867); */

/* Apparence de la scrollbar */
::-webkit-scrollbar
  width: 12px

/* Track */
::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
  -webkit-border-radius: 10px
  border-radius: 10px

/* Handle */
::-webkit-scrollbar-thumb
  -webkit-border-radius: 10px
  border-radius: 10px
  background-image: linear-gradient(to bottom, #dfe9f3 0%, white 100%)
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5)
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5)

::-webkit-scrollbar-thumb:window-inactive
  background-image: linear-gradient(to bottom, #dfe9f3 0%, white 100%)
</style>
<style>
.role-id-1 {
  /* ADMIN */
  background-color: #dab239;
}
.role-id-2 {
  /* RA */
  background-color: #2d3258;
}
.role-id-3 {
  /* CP */
  background-color: #2e4b77;
}
.role-id-4 {
  /* RT */
  background-color: #080e28;
}
.role-id-5 {
  /* IP */
  background-color: #22949f;
}
.role-id-6 {
  /* TP */
  background-color: #54bd9f;
}
.role-id-7 {
  /* ADMIN_ENT */
  background-color: #924748;
}
.role-id-8 {
  /* ADMIN_ENT */
  background-color: #6a9fee;
}
.role-id-9 {
/* APPRENTI */
background-color: #5fb593;
}
.role-id-10 {
/* STAGIAIRE */
background-color: #34c5ca;
}
</style>
