import Vue from "vue";
import * as types from "./mutation_types";

export default {
  [types.REQUETE_AUTHENTIFICATION](state) {
    state.etatAuthentification = "loading";
  },
  [types.AUTHENTIFICATION_REUSSIE](state, { token, user }) {
    state.etatAuthentification = "success";
    state.tokenJwt = token;
    user.ressources= user.ressources.filter(ressource=>ressource.actif)
    Vue.set(state, "utilisateur", user);
  },
  [types.ERREUR_AUTHENTIFICATION](state) {
    state.etatAuthentification = "error";
    state.tokenJwt = "";
    Vue.set(state, "utilisateur", {});
    localStorage.removeItem("token");
  },
  [types.DECONNEXION](state) {
    state.etatAuthentification = "";
    state.tokenJwt = "";
    Vue.set(state, "utilisateur", {});
    localStorage.removeItem("token");
  },
  [types.SET_UTILISATEUR](state, user) {
    user.ressources= user.ressources.filter(ressource=>ressource.actif)
    Vue.set(state, "utilisateur", user);
  },
  [types.SET_CONFIG_DETAILS_PROJETS](
    state,
    { tachesOuvertes, projetsOuverts }
  ) {
    Vue.set(
      state.configuration.detailsProjets,
      "tachesOuvertes",
      tachesOuvertes
    );
    Vue.set(
      state.configuration.detailsProjets,
      "projetsOuverts",
      projetsOuverts
    );
  },
  [types.SET_ID_TACHE_SELECTIONNEE](state, idTache) {
    state.configuration.idTacheSelectionnee = idTache;
  },
  [types.SET_ITEM_ITEM_SELECTIONEE_POUR_POINTAGE](state,id){
    state.configuration.itemSelectionnePourPointage=id;
  },
  [types.SET_TACHE_SELECTIONNEE](state, tache) {
    Vue.set(state.configuration, "tacheSelectionnee", tache);
  },
  [types.SET_ID_RESSOURCE_SELECTIONNEE](state, ressources) {
    Vue.set(state.configuration, "ressourcesSelectionnee", ressources);
  },
  [types.SET_ID_GROUPE_SELECTIONNE](state, idGroupe) {
    Vue.set(state.configuration, "idGroupeSelectionne", idGroupe);
  },
  [types.SET_ID_PROJET_SELECTIONNE](state, idProjet) {
    Vue.set(state.configuration, "idProjetSelectionne", idProjet);
  },
  [types.SET_VERSION_IHM](state, versionIhm) {
    Vue.set(state.utilisateur, "ihmVersion", versionIhm);
  },
  [types.REMOVE_PROJECT_FROM_CONFIG_AND_FAVORITES](state, idProjet){
    if(state.configuration.idProjetSelectionne === idProjet){
      state.configuration.idProjetSelectionne = null;
    }
    state.utilisateur.favoris = state.utilisateur
    .favoris.filter((favori)=> favori.idProjet!=idProjet);
  },
};
