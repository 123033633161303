export default () => ({
  etatAuthentification: "",
  tokenJwt: localStorage.getItem("token") || "",
  utilisateur: {},
  configuration: {
    idGroupeSelectionne: null, // Utilisé pour TimelinePointage
    // idTacheSelectionnee est utilisé pour être réactif et ne pas attendre
    // le retour de l'API tacheSelectionnee pour commencer l'affichage
    idTacheSelectionnee: null,
    tacheSelectionnee: {},
    //utiliser pour selectione une tache ou une absence pour dans la vue pointage
    // idTache si une tache, le nom de l'absence si absence
    itemSelectionnePourPointage: null,
    ressourcesSelectionnee: null, // Utilisé pour pointer sur un autre compte
    // les projets affichés sur la page d'accueil sont une combinaison des
    // favoris et du projet selectionné s'il existe
    idProjetSelectionne: null,
    detailsProjets: {
      tachesOuvertes: {}, // idProjet: [idTache, ...]
      projetsOuverts: [], // [idProjet, ...]
    },
  },
});
