<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Projets).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      titre="Feuille de pointages"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"
      :saving="saving"
      :afficher-message-champs-obligatoire="false"
      @sauvegarder="telechargerPdf"
      v-on="$listeners"
    >
      <template #champs="">
        <v-row>
          <v-col class="text-center body-1" cols="12">{{ data.title }}</v-col>
          <v-col cols="12">
            <!-- <v-date-picker
              v-model="picker"
              full-width
              :landscape="$vuetify.breakpoint.smAndUp"
              type="month"
              locale="fr"
              color="#0097A7"
              @change="dateSelected($event)"
            ></v-date-picker> -->
            <!-- id utilisé pour la génération du pdf -->
            <v-select
              v-if="listEntreprise.length > 1"
              v-model="idEntreprise"
              hide-details
              label="Selectionner une entreprise *"
              style="margin-bottom: 5px"
              required
              outlined
              :items="listEntreprise"
              item-text="nomEntreprise"
              item-value="idEntreprise"
            ></v-select>
            <table
              id="tableau-pointages"
              style="width: 100%"
              class="tab-pointage ma-0"
            >
              <thead class="thead-style">
                <tr>
                  <th width="60">Jour</th>
                  <th>Désignation</th>
                  <th>Affaire</th>
                  <th width="60">Nb jour</th>
                  <th width="80">Dépl.(EUR)</th>
                  <th width="80">Ast.(EUR)</th>
                </tr>
              </thead>
              <tbody class="tbody-style">
                <tr
                  v-for="(ligne, index) in feuilleDePointage"
                  :key="index"
                  :class="ligne.type"
                >
                  <td>{{ ligne.jour }}</td>
                  <td class="text-truncate" style="max-width: 1px">
                    {{ ligne.projet.slice(0, 45) }}
                  </td>
                  <td>{{ ligne.numeroDAffaire }}</td>
                  <td class="text-right">
                    {{ Math.round(ligne.nbJour * 100) / 100 }}
                  </td>
                  <td class="text-right"></td>
                  <td class="text-right"></td>
                </tr>
              </tbody>
              <tr class="tfoot-travail tfoot-style">
                <td></td>
                <td></td>
                <td class="text-right">Travail :</td>
                <td :test-auto="nomPage + '_Travail '" class="text-right">
                  {{ Math.round(getSommeJourTravailles * 100) / 100 }} J
                </td>
                <td class="text-right">0 EUR</td>
                <td class="text-right">0 EUR</td>
              </tr>
              <tr class="tfoot-absences tfoot-style">
                <td></td>
                <td></td>
                <td class="text-right">Absences :</td>
                <td :test-auto="nomPage + '_Absences '" class="text-right">
                  {{ Math.round(getSommeJourAbsences * 100) / 100 }} J
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapState, mapGetters } from "vuex";
import RULES from "@/utils/enums";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Api from "@/api/kuberact";
import { heureFormaterToMinutes } from "@/utils/transform";
export default {
  components: {
    FormulairePopUp,
  },
  props: {
    ressources: {
      type: Array,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
    data: {
      // data.elementsCalendrier[YYYY-MM-DD][matin|soir]{pointage}
      // data.taches[idTache]{tache}
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    saving: false,
    rules: RULES,
    pointages: {},
    idEntreprise: null,
    listEntreprise: [],
  }),
  computed: {
    ...mapState("entreprise", ["entreprises", "utilisateurs", "projets"]),
    ...mapGetters("entreprise", ["getRole"]),

    feuilleDePointage: function() {
      let tableauPointages = [];
      const date = new Date(this.data["date"]);
      const nombreDeJoursDansLeMois = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      const anneeMoisISO = date.toISOString().slice(0, 7); // YYYY-MM
      const numeroMoisFormate = (date.getMonth() + 1 + "").padStart(2, "0"); // padding '01' pour janvier
      for (
        let numeroJour = 1;
        numeroJour <= nombreDeJoursDansLeMois;
        numeroJour++
      ) {
        const numeroJourFormate = (numeroJour + "").padStart(2, "0"); //padding '01' pour le 1er
        const cleElementCalendrier = `${anneeMoisISO}-${numeroJourFormate}`;
        const dateElement = new Date(cleElementCalendrier);
        if (dateElement.getDay() == 6 || dateElement.getDay() == 0) {
          // Weekend
          // type: 'weekend' | 'pointage' | 'absence' | 'manque'
          tableauPointages.push({
            jour: `${numeroJourFormate}/${numeroMoisFormate}`,
            projet: "",
            numeroDAffaire: "",
            nbJour: "",
            type: "weekend",
          });
        } else {
          if (!(cleElementCalendrier in this.pointages)) {
            // S'il n'y a pas de pointage sur cette journée dans le calendrier
            tableauPointages.push({
              jour: `${numeroJourFormate}/${numeroMoisFormate}`,
              projet: "Pas de pointage sur cette journée !",
              numeroDAffaire: "",
              nbJour: "",
              type: "manque",
            });
          } else {
            const listePointageDuJour = this.pointages[
              `${anneeMoisISO}-${numeroJourFormate}`
            ];
            let ligneTableauPointage = {};
            for (let j = 0; j < listePointageDuJour.length; j++) {
              const idProjet = listePointageDuJour[j]["id_projet"];
              if (
                listePointageDuJour[j]["id_ressource"] === this.idRessource &&
                listePointageDuJour[j]["id_ressource"] in this.utilisateurs
              ) {
                ligneTableauPointage = {
                  jour: `${numeroJourFormate}/${numeroMoisFormate}`,
                  projet: isNaN(idProjet) //si est une Absence
                    ? idProjet
                    : this.projets[idProjet].nomProjet,
                  numeroDAffaire: isNaN(idProjet)
                    ? ""
                    : this.projets[idProjet].numAffaire,
                  nbJour: listePointageDuJour[j]["nb_jour"],
                  type: isNaN(idProjet) ? "absence" : "pointage",
                };
                tableauPointages.push(ligneTableauPointage);
              } else {
                ligneTableauPointage = {
                  jour: `${numeroJourFormate}/${numeroMoisFormate}`,
                  projet: this.entreprises[
                    this.ressources.filter(
                      (ressource) =>
                        ressource.idRessource ===
                        listePointageDuJour[j]["id_ressource"]
                    )[0].idEntreprise
                  ].nomEntreprise,
                  numeroDAffaire: "",
                  nbJour: listePointageDuJour[j]["nb_jour"],
                  type: "autre_entreprise",
                };
                tableauPointages.push(ligneTableauPointage);
              }
            }
          }
        }
      }

      return tableauPointages;
    },
    getSommeJourTravailles: function() {
      return this.feuilleDePointage.reduce(
        (acc, ligne) => (ligne.type === "pointage" ? acc + ligne.nbJour : acc),
        0
      );
    },
    getSommeJourAbsences: function() {
      return this.feuilleDePointage.reduce(
        (acc, ligne) => (ligne.type === "absence" ? acc + ligne.nbJour : acc),
        0
      );
    },
    idRessource: function() {
      return this.ressources.filter(
        (ressource) => ressource.idEntreprise === this.idEntreprise
      )[0].idRessource;
    },
  },
  watch: {
    //
  },
  created() {
    this.getExportPointage();
  },
  mounted() {
    //
  },
  methods: {
    heureFormaterToMinutes: heureFormaterToMinutes,
    getExportPointage() {
      this.loading = true;
      const date = new Date(this.data["date"]);
      const nombreDeJoursDansLeMois = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      const anneeMoisISO = date.toISOString().slice(0, 7); // YYYY-MM
      const debut = `${anneeMoisISO}-01`;
      const fin = `${anneeMoisISO}-${nombreDeJoursDansLeMois}`;
      let listidRessource = this.ressources.map(function(ressource) {
        return ressource.idRessource;
      });
      Api.getExportPointage(listidRessource, debut, fin)
        .then((responce) => {
          this.pointages = responce.data;
          let ListRessourceId = [];
          for (const day in this.pointages) {
            for (const pointage in this.pointages[day]) {
              if (
                !ListRessourceId.includes(
                  this.pointages[day][pointage]["id_ressource"]
                ) &&
                this.pointages[day][pointage]["id_projet"] in this.projets
              )
                ListRessourceId.push(
                  this.pointages[day][pointage]["id_ressource"]
                );
            }
          }

          let ressourcesIdEntrepriseList = this.ressources
            .filter((ressource) =>
              ListRessourceId.includes(ressource.idRessource)
            )
            .map(function(ressource) {
              return ressource.idEntreprise;
            });
          this.listEntreprise = Object.values(this.entreprises)
            .filter((entreprise) =>
              ressourcesIdEntrepriseList.includes(entreprise.idEntreprise)
            )
            .sort((a, b) => a.nomEntreprise.localeCompare(b.nomEntreprise));
          this.idEntreprise = this.listEntreprise[0].idEntreprise;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    clearData() {
      this.idRessource = undefined;
      this.data = {};
    },
    telechargerPdf() {
      this.saving = true;
      try {
        const pdf = new JsPDF();
        const ressource = this.utilisateurs[this.idRessource];

        const pdfPositionY = pdf.previousAutoTable.finalY || 10;

        const titre = `${ressource.nom.toUpperCase()} ${ressource.prenom} - ${
          this.data.title
        }`;
        const nomfichier = `pointages ${this.data.title}.pdf`;

        pdf.text(titre, 14, pdfPositionY + 5);

        pdf.autoTable({
          startY: pdfPositionY + 10,
          html: "#tableau-pointages",
          useCss: true,
        });
        pdf.save(nomfichier);
        this.$emit("fermer");
      } catch (err) {
        console.log(err);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 0px;
}

.thead-style th,
.tbody-style td,
.tfoot-style td {
  padding: 5px;
}
.thead-style {
  background-color: #004087;
  border: 1px solid #004087;
  color: #fff;
}
.thead-style th {
  text-align: left;
}
.tbody-style td,
.tfoot-style td {
  border: 2px solid #cccccc;
}
.weekend {
  background-color: #dadada;
}
.tfoot-style {
  font-weight: bold;
}
.tfoot-absences {
  background-color: #ffba8c;
  color: black;
}
.tfoot-travail {
  background-color: #004087;
  color: white;
}
.manque {
  background-color: #ff9999;
}
.autre_entreprise {
  background-color: #bed3ce;
}
</style>
