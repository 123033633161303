export const calculInidicateursCumuleesSurDetailsProjets = (
  objetDetailsProjets
) => {
  // Calcul récursif des indicateurs cumulés tâches et projets
  // Structure en entrée de objetDetailsProjets:
  // { idProjet: {
  //                idProjet,
  //                filles:[{
  //                          idTache,
  //                          tempsPrevu,
  //                          tempsPointe,
  //                          tempsResteAFaire,
  //                        }, ...]
  //             },
  // }
  // Structure en sortie de objetDetailsProjets:
  // { idProjet: {
  //                idProjet,
  //                tempsPrevuCumule,
  //                tempsPointeCumule,
  //                tempsResteAFaireCumule,
  //                avancementCumule,
  //                filles:[{
  //                          idTache,
  //                          tempsPrevu,
  //                          tempsPointe,
  //                          tempsResteAFaire,
  //                          tempsPrevuCumule,
  //                          tempsPointeCumule,
  //                          tempsResteAFaireCumule,
  //                          avancementCumule,
  //                        }, ...]
  //             }
  // }

  function calculIndicateursCumuleesSurNoeud(noeud) {
    // Fonction récurvise bottom-up pour calculer et injecter indicateurs et avancements
    // Un noeud est soit un projet, soit une tâche. Ses sous-noeuds sont ici nommés 'filles'
    if (Array.isArray(noeud.filles) && noeud.filles.length) {
      noeud.filles.forEach((fille) => {
        // récursion bottom-up
        calculIndicateursCumuleesSurNoeud(fille);

        // Somme des filles directes (le temps cumulés sera aussi sur les feuilles)
        let tempsCumules = noeud.filles.reduce(
          (somme, fille) => ({
            tempsPrevuCumule: somme.tempsPrevuCumule + fille.tempsPrevuCumule,
            tempsPointeCumule:
              somme.tempsPointeCumule + fille.tempsPointeCumule,
            tempsResteAFaireCumule:
              somme.tempsResteAFaireCumule + fille.tempsResteAFaireCumule,
          }),
          // Valeurs intiales de l'accumulateur 'reduce' fixées avec les valeurs de la tâche
          // '|| 0' est utilisé pour gérer le traitement du projet qui n'a pas ces valeurs
          {
            tempsPrevuCumule: noeud.tempsPrevu || 0,
            tempsPointeCumule: noeud.tempsPointe || 0,
            tempsResteAFaireCumule: noeud.tempsResteAFaire || 0,
          }
        );

        // Calcul de l'avancement cumulé
        let avancementCumule;
        if (tempsCumules.tempsPointeCumule === 0) {
          // calcul par ratio sans pointage
          avancementCumule =
            (tempsCumules.tempsPrevuCumule -
              tempsCumules.tempsResteAFaireCumule) /
              tempsCumules.tempsPrevuCumule || 0;
        } else if (tempsCumules.tempsPrevuCumule === 0) {
          if (Array.isArray(noeud.filles) && noeud.filles.length) {
            // calcul par simple moyenne
            avancementCumule =
              (noeud.filles.reduce(
                (acc, val) => acc + val.avancementCumule,
                0
              ) /
                100 +
                noeud.avancement) /
              (noeud.filles.length + 1);
          } else {
            avancementCumule = noeud.avancement;
          }
        } else {
          // calcul par ratio des pointages
          avancementCumule =
            tempsCumules.tempsPointeCumule /
              (tempsCumules.tempsPointeCumule +
                tempsCumules.tempsResteAFaireCumule) || 0;
        }
        // floor pour éviter d'arrondir à 100% quand la tâche est à 99.99%
        tempsCumules.avancementCumule = Math.floor(avancementCumule * 100);

        Object.assign(noeud, tempsCumules);
      });
    } else {
      // Assigner les temps cumulés aux feuilles par cohérence
      // '|| 0' vérification car le noeud peut être un projet
      Object.assign(noeud, {
        avancementCumule: Math.floor((noeud.avancement || 0) * 100),
        tempsPrevuCumule: noeud.tempsPrevu || 0,
        tempsPointeCumule: noeud.tempsPointe || 0,
        tempsResteAFaireCumule: noeud.tempsResteAFaire || 0,
      });
    }
  }
  Object.values(objetDetailsProjets).forEach((projet) =>
    calculIndicateursCumuleesSurNoeud(projet)
  );
};
