import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import fr from "vuetify/es5/locale/fr";

const opts = {
  lang: {
    locales: { fr },
    current: "fr",
  },
};

export default new Vuetify(opts);
