<template>
  <v-app-bar id="barre-de-navigation" dense app>
    <v-tabs>
      <v-tab to="/" :test-auto="nomPage + '_accueil'">
        <img
          style="height: 35px"
          src="@/assets/time_keeper_light_with_text_pro.svg"
        />
      </v-tab>
      <v-tab
        v-if="estAdministrateur"
        to="/entreprises"
        :test-auto="nomPage + '_entreprises'"
        class="px-0"
      >
        Entreprises
      </v-tab>
      <v-tab to="/projets" :test-auto="nomPage + '_projets'" class="px-0">
        Projets
      </v-tab>
      <v-tab
        v-if="estResponsable"
        to="/ressources"
        :test-auto="nomPage + '_ressources'"
        class="px-0"
      >
        Ressources
      </v-tab>
      <v-tab
        v-if="estResponsable"
        to="/groupes"
        :test-auto="nomPage + '_groupes'"
        class="px-0"
      >
        Groupes
      </v-tab>
      <v-tab to="/pointages" :test-auto="nomPage + '_pointages'" class="px-0">
        Pointages
      </v-tab>
      <v-spacer></v-spacer>
      <v-tab
        class="px-0"
        :test-auto="nomPage + '_utilisateur'"
        @click="ouvrirFormulaireUtilisateur"
      >
        {{ utilisateur.prenom.substring(0, 1) }}.
        {{ utilisateur.nom.substring(0, 1) }}.
        <v-icon>mdi-account-edit</v-icon>
      </v-tab>
      <v-tab
        class="px-0"
        :test-auto="nomPage + '_deconnexion'"
        style="min-width: 50px"
        @click="deconnexionUtilisateur()"
      >
        <v-icon color="red darken-3">mdi-power</v-icon>
      </v-tab>
      <v-dialog
        v-model="afficherNotesDeRelease"
        fullscreen
        hide-overlay
        transition="dialog-top-transition"
        @keydown.esc="afficherNotesDeRelease = false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :test-auto="nomPage + '_aide'"
            height="100%"
            v-bind="attrs"
            text
            v-on="on"
          >
            <div>
              <v-icon small style="display: block">mdi-help-circle</v-icon>
              <span style="font-size: x-small">{{ version }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark class="gradient-bleu">
            <v-toolbar-title>
              <v-row no-gutters class="align-center">
                <v-icon class="mr-2"> mdi-help-circle </v-icon>
                <span>Aide</span>
              </v-row>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="afficherNotesDeRelease = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="notes-de-version">
            <h1>Support</h1>
            <p style="margin-top: 2em">
              Un bug à remonter, une question à poser ou une évolution à
              suggérer ? Contactez-nous sur le portail :
              <a
                href="https://actemium-paris-transport.atlassian.net/servicedesk/customer/portal/7"
                target="_blank"
                >https://actemium-paris-transport.atlassian.net/servicedesk/customer/portal/7</a
              >
            </p>
            <!-- eslint-disable-next-line 'v-html' directive can lead to XSS attack  vue/no-v-html -->
            <div v-html="notesDeVersion"></div>
          </div>
        </v-card>
      </v-dialog>
    </v-tabs>
    <!-- </v-toolbar> -->
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ENUMS from "@/utils/enums";
import DOMPurify from "dompurify";
import marked from "marked";
import releaseNotes from "@/assets/release_notes.md";
import { EventHub } from "@/event-hub.js";

export default {
  data: () => ({
    ENUMS: ENUMS,
    // v2.6.2 is a version placeholder for version/commit injected by the CI
    // It should be on one ligne (after value replacement.. so count 10 characters)
    version: "v2.6.2",
    afficherNotesDeRelease: false,
    nomPage: "bareDeNavigation",
    //
  }),
  computed: {
    ...mapState("entreprise", ["roles", "profils"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", [
      "nomRole",
      "estAdministrateur",
      "estResponsable",
    ]),
    notesDeVersion() {
      return DOMPurify.sanitize(marked(releaseNotes));
    },
  },
  watch: {
    //
  },
  created() {
    this.afficherNotesDeReleaseSiNouvelleVersion();
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("utilisateur", ["setVersionIhm", "deconnexion"]),

    deconnexionUtilisateur() {
      this.$router.push("/connexion");
      this.deconnexion();
    },
    afficherNotesDeReleaseSiNouvelleVersion() {
      // this.utilisateur.ihmVersion corresponds to the last version
      // the user has already seen
      if (this.version !== this.utilisateur.ihmVersion) {
        this.setVersionIhm(this.version)
          .then(() => (this.afficherNotesDeRelease = true))
          .catch((err) => console.log(err));
      }
    },
    ouvrirFormulaireUtilisateur() {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "utilisateur",
      });
    },
  },
};
</script>

<style scoped>
/* Cacher le surlignage des router-link */
a {
  text-decoration: none;
}
.v-tab {
  color: #004087 !important;
}
.v-tab--active {
  color: #9cc41c !important;
}
.notes-de-version {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}
::v-deep h1 {
  margin-top: 1em;
}
::v-deep h2 {
  margin-top: 1.3em;
}
::v-deep h3 {
  font-weight: normal;
  font-size: 0.83em;
}
::v-deep h4 {
  margin-top: 1em;
}
</style>
