export const SET_UTILISATEURS = "SET_UTILISATEURS";
export const SET_ENTREPRISES = "SET_ENTREPRISES";
export const REPLACE_ENTREPRISE = "ADD_OR_REPLACE_ENTREPRISE";
export const SET_PROFILS ="SET_PROFILS";
export const ADD_OR_REPLACE_PROFIL ="ADD_OR_REPLACE_PROFIL"
export const SET_PROJETS = "SET_PROJETS";
export const SET_ROLES = "SET_ROLES";
export const SET_DETAILS_PROJETS = "SET_DETAILS_PROJETS";
export const ADD_OR_REPLACE_DETAILS_PROJETS = "ADD_OR_REPLACE_DETAILS_PROJETS";
export const SET_GROUPES = "SET_GROUPES";
export const ADD_OR_REPLACE_UTILISATEUR = "ADD_OR_REPLACE_UTILISATEUR";
export const ADD_OR_REPLACE_PROJET = "ADD_OR_REPLACE_PROJET";
export const REMOVE_PROJET = "REMOVE_PROJET";
export const ADD_OR_REPLACE_GROUPE = "ADD_OR_REPLACE_GROUPE";
export const REMOVE_GROUPE = "REMOVE_GROUPE";
export const REMOVE_TACHE_DE_DETAILS_PROJETS =
  "REMOVE_TACHE_DE_DETAILS_PROJETS";
export const SET_ENTREPRISE_SELECTIONNEE =
  "SET_ENTREPRISE_SELECTIONNEE";
export const SET_UTILISATEURS_BY_ENTREPRISE_SELECTIONNEE =
  "SET_UTILISATEURS_BY_ENTREPRISE_SELECTIONNEE";
