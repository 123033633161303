export const getPrenomNom = (state) => (
  idRessource,
  prenomCourt = false,
  nomCourt = false
) => {
  if (idRessource in state.utilisateurs) {
    const prenom = state.utilisateurs[idRessource].prenom;
    const nom = state.utilisateurs[idRessource].nom;
    return `${prenomCourt ? prenom.charAt(0) + "." : prenom} ${
      nomCourt ? nom.charAt(0) + "." : nom
    }`;
  } else {
    return "Inconnu.e";
  }
};

export const getRole = (state) => (
  idRessource
) => {
  if (idRessource in state.utilisateurs) {
    return  state.utilisateurs[idRessource].idRole;
  } else {
    return "Inconnu.e";
  }
};


export const AutorisePointageALHeure = (state) => (idRessource) => {
  let ress = false;
  for(const ressource of state.utilisateurs[idRessource].ressources){
    ress = ress || state.entreprises[ressource.idEntreprise].autoriserPointageAHeure;
  }
  return ress;
};

//Retourn l'identreprise d'une ressource 
export const getEntreprisebyIdRessource = (state) => (idRessource) => {
  let listRessource = state.utilisateurs[idRessource].ressources;
  for(const ressource of listRessource) {
    if(ressource.idRessource === idRessource)
      return ressource.idEntreprise
  }
  return -1;
};
// retourne true si l'utilisateur et la tache sont dans la meme entreprise  
export const utilisateurAndTacheInSameEntreprise = (state) => (utilisateur,tache) => {
  let listRessource = utilisateur.ressources;
  let tacheidEntreprise = state.projets[tache.idProjet].idEntreprise;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === tacheidEntreprise)
      return true
  }
  return false;
};

// retourne l'id de la ressource correspondant a l'entreprise de la tache   
export const getIdRessourceByTache = (state) => (utilisateur,tache) => {
  let listRessource = utilisateur.ressources;
  let tacheidEntreprise = state.projets[tache.idProjet].idEntreprise;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === tacheidEntreprise)
      return ressource.idRessource
  }
  return -1;
};

// retourne true si l'utilisateur et le projet sont dans la meme entreprise  
export const utilisateurAndProjetInSameEntreprise = () =>  (utilisateur,projet) => {
  let listRessource = utilisateur.ressources;
  let tacheidEntreprise = projet.idEntreprise;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === tacheidEntreprise)
      return true;
  }
  return false;
};

// retourne l'id de la ressource correspondant a l'entreprise du projet 
export const getIdRessourceByProjet= () => (utilisateur,projet) => {
  let listRessource = utilisateur.ressources;
  let tacheidEntreprise = projet.idEntreprise;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === tacheidEntreprise)
      return ressource.idRessource;
  }
  if(utilisateur.idRole===1){
    return listRessource[0].idRessource;
  }
  return -1;
};

// retourne idRessource de l'utilisateur si il a une ressource active dans l'entreprise  
export const utilisateurToidRessourceByIdEntreprise = () =>  (utilisateur,idEntreprise) => {
  let listRessource = utilisateur.ressources;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === idEntreprise)
      return ressource.idRessource;
  }
  return -1;
};

export const getIdEntrepriseByidRessource= (state) => (idRessource) => {
  if(idRessource in state.utilisateurs)
    return state.utilisateurs[idRessource].ressources.filter(
      (ressource) => ressource.idRessource == idRessource
    )[0].idEntreprise;
  return null;
};

export const getidRessourceByIdEntreprise= () => (utilisateur,idEntreprise) => {
  let listRessource = utilisateur.ressources;
  for (const ressource of listRessource) {
    if(ressource.idEntreprise === idEntreprise)
      return ressource.idRessource;
  }
  return -1;
};

export const getNomEntrepriseByidRessource= (state) => (idRessource) => {
  let idEntreprise = null;
  if(idRessource in state.utilisateurs)
    idEntreprise =  state.utilisateurs[idRessource].ressources.filter(
      (ressource) => ressource.idRessource == idRessource
    )[0].idEntreprise;
  return idEntreprise ? state.entreprises[idEntreprise].nomEntreprise : "Inconnu.e";
};

export const getActifByidRessource= (state) => (idRessource) => {
  return state.utilisateurs[idRessource].ressources.filter(
    (ressource) => ressource.idRessource == idRessource
  )[0].actif;
};

export const getNomEntrepriseByidEntreprise = (state)  => (idEntreprise) => {
    return state.entreprises[idEntreprise].nomEntreprise; 
};

//retourne liste des Ressource de l'entreprise selectionnée
export const ListEntRessourceToRessourceIdList = (ressources) =>{
  return ressources.map(function (ressource) {
    return ressource.idRessource
  });
}
