export const REQUETE_AUTHENTIFICATION = "REQUETE_AUTHENTIFICATION";
export const AUTHENTIFICATION_REUSSIE = "AUTHENTIFICATION_REUSSIE";
export const ERREUR_AUTHENTIFICATION = "ERREUR_AUTHENTIFICATION";
export const DECONNEXION = "DECONNEXION";
export const SET_UTILISATEUR = "SET_UTILISATEUR";
export const SET_CONFIG_DETAILS_PROJETS = "SET_CONFIG_DETAILS_PROJETS";
export const SET_ID_TACHE_SELECTIONNEE = "SET_ID_TACHE_SELECTIONNEE";
export const SET_TACHE_SELECTIONNEE = "SET_TACHE_SELECTIONNEE";
export const SET_ID_RESSOURCE_SELECTIONNEE = "SET_ID_RESSOURCE_SELECTIONNEE";
export const SET_ID_GROUPE_SELECTIONNE = "SET_ID_GROUPE_SELECTIONNE";
export const SET_ID_PROJET_SELECTIONNE = "SET_ID_PROJET_SELECTIONNE";
export const SET_VERSION_IHM = "SET_VERSION_IHM";
export const REMOVE_PROJECT_FROM_CONFIG_AND_FAVORITES = "REMOVE_PROJECT_FROM_CONFIG_AND_FAVORITES";
export const SET_ITEM_ITEM_SELECTIONEE_POUR_POINTAGE = "SET_ITEM_ITEM_SELECTIONEE_POUR_POINTAGE"