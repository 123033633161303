// Enums
const roleAdministateur = ["ADMIN",'ADMIN_ENT'];
const rolesManager = ["CP","CPj", "RA", "RT", ...roleAdministateur];
const rolesUtilisateur = ["TP", "IP", ...rolesManager];

// Rules
const nonVide = (v) => !!v || "Le champ doit être renseigné";

const email = (v) => /^.+@.+\..{2,4}$/.test(v) || "L'e-mail n'est pas valide";

const mdpCompliqueOuVide = (v) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+;])(?=.{8,})/.test(v) ||
  !v ||
  "Minimum 8 caractères avec au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial !@#$%^&*+;";

const matricule = (v) => /^(?=.*[0-9]{8})/.test(v) || "Le matricule n'est pas valide";

export default {
  roleAdministateur,
  rolesUtilisateur,
  rolesManager,
  nonVide,
  email,
  mdpCompliqueOuVide,
  matricule,
};
