<template>
  <div >
    <formulaire-pop-up
      :titre="titreProjet"
      maximum="1300px"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"  
      :saving="saving"
      :afficher-message-champs-obligatoire="false"
      @sauvegarder="exportExcel()"
      v-on="$listeners"
    >
      <template #champs>
        <v-row>
          <v-col
          cols="16"
          >
            <v-data-table
              id = "export-excel"
              :headers="entete"
              :items="tableauExportTaches"
              :sort-by="['idCumule', 'rank']"
              :items-per-page="-1"
              class="elevation-1"
              dense
              :item-class="itemRowBackground"
            >
              <template 
                v-if="entete.map((header) => header.value).includes('idRessource')"
                v-slot:[`item.idRessource`]="{ item }"       
              >
                <slot 
                  v-if="item.idRessource!=null"
                > 
                  {{ getPrenomNom(item.idRessource) }}         
                </slot>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapGetters } from "vuex";
import xlsx from "xlsx";
export default {
  components: {
    FormulairePopUp,
  },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    saving: false,
  }),
  computed: {
    ...mapGetters("entreprise", ["getPrenomNom"]),

    titreProjet : function(){
      let titreProjet = this.data.title;
      return titreProjet;
    },

    entete: function() {
      let headers = [
        {text:"Categorie", value:"categorie", align:" start", width:"110px"},
        {text:"Nom tache", value:"nom", align:" start"},
        {text:"Nom travailleur", value:"idRessource", align:" start"},
        {text:"Temps consommé", value:"tempsPointe", align:" start", width:"60px"},
        {text:"Avancement", value:"avancement", align:" start", width:"60px"},
        {text:"Temps prevu", value:"tempsPrevu", align:" start", width:"60px"},
        {text:"Temps projete", value:"tempsProjete", align:" start", width:"60px"},
        {text:"Temps reste a faire", value:"tempsResteAFaire", align:" start",
         width:"60px"},
        {text:"Id cumulés", value:"idCumule", align:" start"},
        {text:"Rang", value:"rank", align:" start", width:"40px"},
        {text:"Est mère", value:"estMere", align:" d-none"},
        {text:"Noms cumulés", value:"nomCumule", align:" d-none"},
        {text:"Id tâche mère", value:"idTacheMere", align:" d-none"},
        {text:"Nom tâche mère", value:"nomTacheMere", align:" d-none"},
        {text:"Id", value:"id", align:" d-none"},
      ];
      return headers;
    }, // fin entete

    tableauExportTaches : function(){
      //On récupère le tableau de données
      const listTachesEtTravailleurs = 
        Object.values(this.data.tableauTachesEtTravailleurs).map(
          (elem) => ({...elem,}),
        );
      //On récupère les sous-totaux des taches
      const tableauSousTotaux = new Array();
          let totalTempsPointe = 0;
          let totalTempsPrevu = 0;
          let totaltempsProjete = 0;
          let totalTempsResteAFaire =0;
      let sousTotalTache = {};
      for (let i = 0; i < listTachesEtTravailleurs.length; i++) {
        if (listTachesEtTravailleurs[i].estMere == true
         || listTachesEtTravailleurs[i].rank ===1) 
         {
          let cumulTempsPointe = 0;
          let cumulTempsPrevu = 0;
          let cumultempsProjete = 0;
          let cumulTempsResteAFaire =0;
          for (let j = 0; j < listTachesEtTravailleurs.length; j++) {
            if (listTachesEtTravailleurs[j].categorie==="tache" && 
              (listTachesEtTravailleurs[j].idCumule).toString()
              .includes(listTachesEtTravailleurs[i].idTache) || 
              listTachesEtTravailleurs[j].id === listTachesEtTravailleurs[i].id)
            {
              cumulTempsPointe = cumulTempsPointe + 
                parseFloat(listTachesEtTravailleurs[j].tempsPointe);
              cumulTempsPrevu = cumulTempsPrevu + 
                parseFloat(listTachesEtTravailleurs[j].tempsPrevu);
              cumultempsProjete = cumultempsProjete + 
                parseFloat(listTachesEtTravailleurs[j].tempsProjete);
              cumulTempsResteAFaire = cumulTempsResteAFaire + 
                parseFloat(listTachesEtTravailleurs[j].tempsResteAFaire);               
            }              
          }
          sousTotalTache = {
            id : "st-"+listTachesEtTravailleurs[i].id,
            idCumule : listTachesEtTravailleurs[i].idCumule,
            categorie : "Sous total",
            nom : listTachesEtTravailleurs[i].nom,
            rank : listTachesEtTravailleurs[i].rank - 1,
            idTache : listTachesEtTravailleurs[i].idTache,
            tempsPointe : cumulTempsPointe.toFixed(1),
            tempsPrevu : cumulTempsPrevu.toFixed(1),
            tempsProjete : cumultempsProjete.toFixed(1),
            tempsResteAFaire : cumulTempsResteAFaire.toFixed(1),
            avancement : listTachesEtTravailleurs[i].avancementCumule,
            estMere : false,
          };
          tableauSousTotaux.push(sousTotalTache);
          //On récupère le total du projet selectionné
          if (sousTotalTache.rank === 0) {
            totalTempsPointe = totalTempsPointe + cumulTempsPointe;
            totalTempsPrevu = totalTempsPrevu + cumulTempsPrevu;
            totaltempsProjete = totaltempsProjete + cumultempsProjete;
            totalTempsResteAFaire = totalTempsResteAFaire+ cumulTempsResteAFaire;     
          }
          sousTotalTache = {}; 
        }        
      } //  fin for creation de tableauSousTotaux
      let totalProjet = {
        id : 0,
        idCumule : 0,
        categorie : "Total projet",
        nom : this.data.projetFiltre.nomProjet,
        rank : 0,
        tempsPointe : totalTempsPointe.toFixed(1),
        tempsPrevu : totalTempsPrevu.toFixed(1),
        tempsProjete : totaltempsProjete.toFixed(1),
        tempsResteAFaire : totalTempsResteAFaire.toFixed(1),
        avancement : this.data.avancementCumuleProjet+"%",
        estMere : false,          
      };
      listTachesEtTravailleurs.push(totalProjet);
      for (let index = 0; index < tableauSousTotaux.length; index++) {
        listTachesEtTravailleurs.push(tableauSousTotaux[index]);  
      }
      let tableauExportTaches = listTachesEtTravailleurs; 
      return tableauExportTaches;
    }, // fin tableauExportTaches
  }, // fin computed
  watch: {
    //
  },
  methods: {
    //Mise en forme des lignes du tableau 
    itemRowBackground: function (item) {
      switch (item.categorie) {
        case "Total projet":
          return 'font-weight-black indigo darken-4 white--text'; 
        case "Sous total":
          return 'indigo darken-1 white--text';       
        case "tache" :
          return 'indigo lighten-4';    
        case "travailleur":
          return 'travailleur'; 
      }
    }, //fin itemRowBackground()
    //excel
    exportExcel(){  
      this.saving = true;
      try {
        const XLSX = xlsx;
        const nomfichier = `${this.data.title}.xlsx`;
        const elt = document.getElementById('export-excel');
        const wb = XLSX.utils.table_to_book(elt, {sheet:"exportProjet"});
            XLSX.writeFile(wb, nomfichier);
        this.$emit("fermer");
      } catch (err) {
        console.log(err);
      } finally {
        this.saving = false;
      }     
    }, //fin exportExcel()
  }, //fin methods
}; //fin export defaut
</script>
<style scoped>
</style>
