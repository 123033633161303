import Api from "@/api/kuberact";
import jwt_decode from "jwt-decode";
import * as types from "./mutation_types";

export const connexion = ({ commit }, { email, password }) => {
  commit(types.REQUETE_AUTHENTIFICATION);
  return Api.authentification(email, password)
    .then((response) => {
      const token = response.data.token.jwt;
      const user = response.data.utilisateur;
      localStorage.setItem("token", token);
      commit(types.AUTHENTIFICATION_REUSSIE, { token, user });
    })
    .catch((err) => {
      commit(types.ERREUR_AUTHENTIFICATION);
      throw err;
    });
};

export const deconnexion = ({ commit }) => {
  commit(types.DECONNEXION);
  localStorage.removeItem("token");
  // Reload de la page pour nettoyer le store et ne pas laisser de données
  // sur le poste utilisateur (c'est plus rapide et maintenable que de clean
  // le store en respectant la 'reactivity' vuex)
  location.reload();
};

export const rechargerUtilisateur = ({ commit, state }) => {
  // TOOD: this doesn't scale to one ressource having multiple users in database
  // Chargement des données de l'utilisateur
  // Nécessite la présence du token dans le store, ou dans le stockage
  if (state.tokenJwt) {
    // TODO: Change resourceId from java user service to idUtilisateur if this is a user and not a ressource
    const idUtilisateur = parseInt(jwt_decode(state.tokenJwt).utilisateurId);
    if (idUtilisateur > 0) {
      commit(types.REQUETE_AUTHENTIFICATION);
      return Api.getUtilisateur(idUtilisateur)
        .then((response) => {
          commit(types.AUTHENTIFICATION_REUSSIE, {
            user: response.data,
            token: state.tokenJwt,
          });
        })
        .catch((err) => {
          commit(types.ERREUR_AUTHENTIFICATION);
          throw err;
        });
    } else {
      commit(types.ERREUR_AUTHENTIFICATION);
    }
  } else {
    commit(types.ERREUR_AUTHENTIFICATION);
  }
};

export const modifierFavori = (
  { commit, state },
  { favoriEtatSouhaite, idProjet }
) => {
  const shallowCopyUtilisateur = { ...state.utilisateur };
  // TODO : changer ce format étrange de l'API d'attendre des favoris : [{idProjet: 2}, ...]

  if (favoriEtatSouhaite) {
    shallowCopyUtilisateur.favoris = [
      ...shallowCopyUtilisateur.favoris,
      { idProjet },
    ];
  } else {
    shallowCopyUtilisateur.favoris = shallowCopyUtilisateur.favoris.filter(
      (favori) => favori.idProjet !== idProjet
    );
  }
  return Api.updateUtilisateur(
    shallowCopyUtilisateur.idUtilisateur,
    shallowCopyUtilisateur
  ).then((response) => {
    commit(types.SET_UTILISATEUR, response.data);
  });
};

export const sauvegardeConfigurationDetailsProjets = (
  { commit },
  { tachesOuvertes, projetsOuverts }
) =>
  commit(types.SET_CONFIG_DETAILS_PROJETS, { tachesOuvertes, projetsOuverts });

export const updateIdTacheSelectionnee = ({ commit }, idTache) => {
  commit(types.SET_ID_TACHE_SELECTIONNEE, idTache);
};

export const updateItemSelectionnePourPointage = ({ commit }, id) => {
  commit(types.SET_ITEM_ITEM_SELECTIONEE_POUR_POINTAGE, id);
};

export const recupererTacheSelectionnee = ({ commit, state }) => {
  return Api.getTache(
    state.configuration.idTacheSelectionnee
  ).then((response) => commit(types.SET_TACHE_SELECTIONNEE, response.data));
};

export const clearTacheSelectionnee = ({ commit }) => {
  commit(types.SET_ID_TACHE_SELECTIONNEE, null);
  commit(types.SET_TACHE_SELECTIONNEE, {});
};

export const setRessourcesSelectionnee = ({ commit }, ressources) =>
  commit(types.SET_ID_RESSOURCE_SELECTIONNEE, ressources);

export const clearRessourcesSelectionnee = ({ commit }) =>
  commit(types.SET_ID_RESSOURCE_SELECTIONNEE, null);

export const setIdGroupeSelectionne = ({ commit }, idGroupe) =>
  commit(types.SET_ID_GROUPE_SELECTIONNE, idGroupe);

export const clearIdGroupeSelectionne = ({ commit }) =>
  commit(types.SET_ID_GROUPE_SELECTIONNE, null);

export const setIdProjetSelectionne = ({ commit }, idProjet) =>
  commit(types.SET_ID_PROJET_SELECTIONNE, idProjet);

export const setVersionIhm = ({ commit, state }, ihmVersion) =>
  Api.updateUtilisateur(state.utilisateur.idUtilisateur, {
    ihmVersion,
  }).then((response) =>
    commit(types.SET_VERSION_IHM, response.data.ihmVersion)
  );
