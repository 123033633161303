<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Ressources).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      titre="Mon compte"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"
      :saving="saving"
      @sauvegarder="sauvegarderUtilisateur"
      @fermer="clearData"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="utilisateurAModifier.nom"
            :test-auto="nomPage + '_nom'"
            autofocus
            :rules="[rules.nonVide]"
            hide-details
            maxlength="50"
            label="Nom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="utilisateurAModifier.prenom"
            :test-auto="nomPage + '_prenom'"
            :rules="[rules.nonVide]"
            hide-details
            maxlength="50"
            label="Prenom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="utilisateurAModifier.mail"
            :test-auto="nomPage + '_email'"
            :rules="[rules.email, rules.nonVide]"
            maxlength="50"
            label="E-mail*"
            required
          ></v-text-field>
        </v-col>
        <v-col class="" cols="12">
          <v-divider></v-divider>

          <div class="body-1 text-center font-weight-medium pt-2 mb-n4">
            Changer le mot de passe
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="nouveauMdp"
            :test-auto="nomPage + '_mdp'"
            :append-icon="afficherMdp ? 'mdi-eye' : 'mdi-eye-off'"
            :type="afficherMdp ? 'text' : 'password'"
            :rules="[rules.mdpCompliqueOuVide]"
            label="Nouveau mot de passe"
            required
            @click:append="afficherMdp = !afficherMdp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="nouveauMdpConfirmation"
            :test-auto="nomPage + '_mdpConfirmer'"
            :append-icon="afficherMdp ? 'mdi-eye' : 'mdi-eye-off'"
            :type="afficherMdp ? 'text' : 'password'"
            :rules="passwordRules"
            label="Confirmer le mot de passe"
            required
            @click:append="afficherMdp = !afficherMdp"
          ></v-text-field>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapState } from "vuex";
import RULES from "@/utils/enums";

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    utilisateurAModifier: {},
    afficherMdp: false,
    rules: RULES,
    nouveauMdp: "",
    nouveauMdpConfirmation: "",
    //
  }),
  computed: {
    ...mapState("utilisateur", ["utilisateur"]),
    passwordRules: function() {
      return [
        this.rules.mdpCompliqueOuVide,
        Object.is(this.nouveauMdp, this.nouveauMdpConfirmation) ||
          "Les mots de passe doivent être identiques",
      ];
    },
    //
  },
  watch: {
    //
  },
  created() {
    this.utilisateurAModifier = {};
    const utilisateurEnMemoire = { ...this.utilisateur };
    this.utilisateurAModifier = {
      idUtilisateur: utilisateurEnMemoire.idUtilisateur,
      prenom: utilisateurEnMemoire.prenom,
      mail: utilisateurEnMemoire.mail,
      nom: utilisateurEnMemoire.nom,
    };
    this.loading = false;
    //
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["updateUtilisateur"]),
    clearData() {
      this.utilisateurAModifier = {};
      this.afficherMdp = false;
      this.nouveauMdp = "";
      this.nouveauMdpConfirmation = "";
    },
    sauvegarderUtilisateur() {
      this.saving = true;
      if (this.nouveauMdp !== null && this.nouveauMdp.length) {
        this.utilisateurAModifier.password = this.nouveauMdp;
      }

      this.updateUtilisateur(this.utilisateurAModifier)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
