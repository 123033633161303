import Api from "@/api/kuberact";
import * as types from "./mutation_types";

export const recupererUtilisateurs = ({ commit }) =>
  Api.getUtilisateurs().then((response) => {
    commit(types.SET_UTILISATEURS, response.data);
  });

export const recupererEntreprises = ({ commit }) =>
  Api.getEntreprises().then((response) => {
    commit(types.SET_ENTREPRISES, response.data);
  });
export const updateEntreprise  = ( { commit },entreprise) =>{
  return Api.updateEntreprise(entreprise.idEntreprise, entreprise).then(
    (response) => {
      commit(types.REPLACE_ENTREPRISE, response.data);
    }
  );
};
export const recupererProfils = ({ commit }) =>
  Api.getProfils().then((response) => {
    commit(types.SET_PROFILS, response.data);
  });
  export const updateProfil = ({ commit },profil) =>
  Api.updateProfil(profil.idRole,profil.idEntreprise,profil).then((response) => {
    commit(types.ADD_OR_REPLACE_PROFIL, response.data);
  });
   
export const recupererProjets = ({ commit }) =>
  Api.getProjets().then((response) => {
    commit(types.SET_PROJETS, response.data);
  });

export const recupererRoles = ({ commit }) =>
  Api.getRoles().then((response) => {
    commit(types.SET_ROLES, response.data);
  });

export const updateDetailsProjets = ({ commit }, listeDesIdsProjet) =>
  Api.getTachesParProjets(listeDesIdsProjet).then((response) =>
    commit(types.ADD_OR_REPLACE_DETAILS_PROJETS, response.data)
  );

export const recupererGroupes = ({ commit }) =>
  Api.getGroupes().then((response) => {
    commit(types.SET_GROUPES, response.data);
  });

export const createUtilisateur = ({ commit,rootGetters }, utilisateur) => {
  // TODO: edit API so that empty array "favoris" is not needed during creation
  utilisateur.favoris = [];
  return Api.createUtilisateur(utilisateur).then((response) => {
    let listEntrepriseUtilisateur = rootGetters["utilisateur/ListRessourceToEntrepriseIdList"];
    commit(types.ADD_OR_REPLACE_UTILISATEUR,{utilisateur:response.data, listEntrepriseUtilisateur:listEntrepriseUtilisateur});
  });
};

export const updateUtilisateur = ({ commit,rootGetters }, utilisateur) =>
  Api.updateUtilisateur(utilisateur.idUtilisateur, utilisateur).then(
    (response) => {
      let listEntrepriseUtilisateur = rootGetters["utilisateur/ListRessourceToEntrepriseIdList"];
      commit(types.ADD_OR_REPLACE_UTILISATEUR, {utilisateur:response.data, listEntrepriseUtilisateur:listEntrepriseUtilisateur});
    }
  );

const gererLesSousElements = async (
  typeElement,
  typeSousElement,
  idElement,
  listeSousElementsInitiale,
  listeIdsRessourceSousElementFinale
) => {
  // typeElement: 'Projet' OU 'Groupe' [Majuscule importante]
  // typeSousElement: 'Suppleant' OU 'Membre' [Majuscule importante] (correspond à la clé de suppresion de l'élément)
  // Gestion de la création/suppression des suppléants/membres
  // lors de la création/màj des projets/groupes
  // La comparaison se fait sur la clé idRessource

  const listeIdsRessourceSousElementInitiale = listeSousElementsInitiale.map(
    (sousElement) => sousElement.idRessource
  );
  let idsRessourceSousElementACreer = listeIdsRessourceSousElementFinale.filter(
    (idRessource) => !listeIdsRessourceSousElementInitiale.includes(idRessource)
  );
  let idsSousElementASupprimer = listeSousElementsInitiale
    .filter(
      (sousElement) =>
        !listeIdsRessourceSousElementFinale.includes(sousElement.idRessource)
    )
    .map((sousElement) => sousElement[`id${typeSousElement}`]);

  return Promise.all([
    ...idsRessourceSousElementACreer.map((idRessource) =>
      Api[`create${typeSousElement}`]({
        [`id${typeElement}`]: idElement,
        idRessource,
      })
    ),
    ...idsSousElementASupprimer.map((idSousElement) =>
      Api[`destroy${typeSousElement}`](idSousElement)
    ),
  ]);
};

export const createProjet = async ({ commit }, projet) => {
  // TODO: edit API to accept a list of "suppleant" at creation time
  // TODO: edit API as we shouldn't need to provide idRessourceCreateur
  //       when creating a projet because the API knows who I am !
  let tmp = null;
  await Api.createProjet(projet)
    .then(async (response) => {
      // Création des suppléants s'il sont passés dans l'objet
      await gererLesSousElements(
        "Projet",
        "Suppleant",
        response.data.idProjet,
        projet.listeSuppleantsInitiale,
        projet.listeIdsRessourceSuppleant
      );
      tmp = response.data.idProjet;
      Api.getSuppleantsParProjet(tmp).then(resSuppleants=>{
        commit(types.ADD_OR_REPLACE_PROJET,
          {... response.data,suppleants:resSuppleants.data});
      }).catch((err) => Promise.reject(err))
    })
    .catch((err) => Promise.reject(err));
  return tmp;
};

export const updateProjet = ({ commit }, projet) => {
  // TODO: c.f. 'createProjet'
  return Api.updateProjet(projet.idProjet, projet)
    .then(async (response) => {
      // Création des suppléants s'il sont passés dans l'objet
      await gererLesSousElements(
        "Projet",
        "Suppleant",
        response.data.idProjet,
        projet.listeSuppleantsInitiale,
        projet.listeIdsRessourceSuppleant
      );
      Api.getSuppleantsParProjet(projet.idProjet).then(resSuppleants=>{
        commit(types.ADD_OR_REPLACE_PROJET, 
          {... response.data,suppleants:resSuppleants.data});
      }).catch((err) => Promise.reject(err))
      
      

    })
    .catch((err) => Promise.reject(err));
};

export const deleteProjet = ({ commit }, idProjet) => {
  return Api.destroyProjet(idProjet).then(() => {
    commit(types.REMOVE_PROJET, idProjet);
    commit('utilisateur/REMOVE_PROJECT_FROM_CONFIG_AND_FAVORITES', idProjet,{root:true});
  });
};

export const createGroupe = async ({ commit }, groupe) => {
  // TODO: edit API to accept a list of "membre" at creation time
  await Api.createGroupe(groupe)
    .then((response) => {
      commit(types.ADD_OR_REPLACE_GROUPE, response.data);
      // Création des membres s'il sont passés dans l'objet
      return gererLesSousElements(
        "Groupe",
        "Membre",
        response.data.idGroupe,
        groupe.listeMembresInitiale,
        groupe.listeIdsRessourceMembre
      );
    })
    .catch((err) => Promise.reject(err));
};

export const updateGroupe = async ({ commit }, groupe) => {
  // TODO: c.f. 'createGroupe'
  await Api.updateGroupe(groupe.idGroupe, {
    ...groupe,
    listeMembresInitiale: null,
    listeIdsRessourceMembre: null,
  })
    .then((response) => {
      commit(types.ADD_OR_REPLACE_GROUPE, response.data);
      // Création des membres s'il sont passés dans l'objet
      return gererLesSousElements(
        "Groupe",
        "Membre",
        response.data.idGroupe,
        groupe.listeMembresInitiale,
        groupe.listeIdsRessourceMembre
      );
    })
    .catch((err) => Promise.reject(err));
};

export const deleteGroupe = ({ commit }, idGroupe) => {
  return Api.destroyGroupe(idGroupe).then(() => {
    commit(types.REMOVE_GROUPE, idGroupe);
  });
};

export const createTache = async ({ dispatch }, tache) => {
  let tmp = null;
  await Api.createTache(tache).then(async (response) => {
    tmp = response.data;
    // On force la mise à jour des details-projets, cf. 'moveTache()'
    // 'await' car on veut attendre la récupération des projets avant de mettre à jour l'IU
    await dispatch("updateDetailsProjets", [tache.idProjet]);
    dispatch("utilisateur/updateIdTacheSelectionnee", response.data.idTache, {
      root: true,
    });
  });
  return tmp;
};
export const duplicateTache = async ({ dispatch },{id, tache}) => {
  let tmp = null;
  await Api.duplicateTache(id,tache).then(async (response) => {
     tmp = response.data;
    // On force la mise à jour des details-projets, cf. 'moveTache()'
    // 'await' car on veut attendre la récupération des projets avant de mettre à jour l'IU
    await dispatch("updateDetailsProjets", [tache.idProjet]);
    dispatch("utilisateur/updateIdTacheSelectionnee", response.data.idTache, {
      root: true,
    });
  });
  return tmp;
};
export const updateTache = ({ dispatch }, tache) => {
  return Api.updateTache(tache.idTache, tache).then(async () => {
    // On force la mise à jour des details-projets, cf. 'moveTache()'
    // Lancement en parallele mais on attends le retour complet avec de terminer la promesse
      await Promise.all([
        dispatch("utilisateur/recupererTacheSelectionnee", null, {
          root: true,
        }),
        dispatch("updateDetailsProjets", [tache.idProjet]),
      ]);
  });
};

export const moveTache = (
  { dispatch },
  { idTacheCible, idProjetCible, tacheSource }
  ) =>
  // Move from one project to another if needed
  Api.updateTache(tacheSource.idTache, {
    idTache: tacheSource.idTache,
    idTacheMere: idTacheCible,
    idProjet: idProjetCible,
  }).then(() => {
    // On force la mise à jour des details-projets -> on ne se prend pas la tête à faire
    // des modifications chirurgicales dans le state tant que le SSE n'est pas implémenté
    // TODO: après l'arrivée du SSE et des notifications de mise à jour de projet
    //       remplacer ces requêtes details-projets par une modification du state vuex
    dispatch("updateDetailsProjets", [
      ...new Set([tacheSource.idProjet, idProjetCible]),
    ]);
  });

export const deleteTache = ({ commit }, { idTache, idProjet }) =>
  Api.destroyTache(idTache).then(() =>
    commit(types.REMOVE_TACHE_DE_DETAILS_PROJETS, { idTache, idProjet })
  );

export const setEntrepriseSelectionnee = ({ commit }, entrepriseSelectionnee) => 
  commit(types.SET_ENTREPRISE_SELECTIONNEE, entrepriseSelectionnee
    ); 

export const setUtilisateursByEntrepriseSelectionnee = ({ commit }) =>
  Api.getUtilisateurs().then((response) => {
    commit(types.SET_UTILISATEURS_BY_ENTREPRISE_SELECTIONNEE, response.data);
  });

