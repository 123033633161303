<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Projets).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      :titre="`${editionMode ? 'Modifier' : 'Créer'} un projet`"
      :nom-page="nomPage"
      :supprimable="editionMode"
      :loading="loading"
      :saving="saving"
      @sauvegarder="sauvegarderProjet"
      @supprimer="supprimerProjet"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12" sm="7">
          <v-text-field
            v-model="projetAModifier.nomProjet"
            :test-auto="nomPage + '_nom'"
            autofocus
            :rules="[rules.nonVide]"
            hide-details
            maxlength="150"
            label="Nom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-switch
            v-model="projetAModifier.actif"
            :test-auto="nomPage + '_actif'"
            inset
            hide-details
            color="green"
            :label="projetAModifier.actif ? 'Projet ouvert' : 'Projet archivé'"
            @change="initialiserDateCloture"
          ></v-switch>
        </v-col>
        <!-- bouton import excel -->
        <v-col v-if="editionMode === false" cols="12" sm="2" class="pb-0">
          <input
            id="upload"
            ref="file"
            type="file"
            style="display: none"
            @change="importExcel"
          />
          <v-btn class="pa-0" text height="100%" @click="$refs.file.click()">
            <div>
              <v-icon style="display: block"> mdi-upload </v-icon>
              <span style="font-size: x-small">Import Excel</span>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="projetAModifier.numAffaire"
            :test-auto="nomPage + '_nAffaire'"
            hide-details
            maxlength="50"
            label="N° d'affaire"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="projetAModifier.idChefDeProjet"
            :test-auto="nomPage + '_chef'"
            label="Chef de projet*"
            required
            hide-details
            :items="listeUtilisateursComplete"
            item-text="labelUtilisateur"
            item-value="idRessource"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            v-model="menuDateDebut"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :test-auto="nomPage + '_dateDebut'"
                :value="dateDebutFormatee"
                label="Date de début*"
                prepend-icon="mdi-calendar"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="projetAModifier.dateDebut"
              @input="menuDateDebut = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!--  -->
        <v-col v-if="!projetAModifier.actif" cols="12" sm="6">
          <v-menu
            v-model="menuDateCloture"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateClotureFormatee"
                label="Date de clôture*"
                prepend-icon="mdi-calendar"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="projetAModifier.dateCloture"
              @input="menuDateCloture = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12">
          <v-autocomplete
            v-model="projetAModifier.listeIdsRessourceSuppleant"
            :test-auto="nomPage + '_suppleants'"
            label="Suppléants"
            required
            hide-details
            :items="listeUtilisateursComplete"
            item-text="labelUtilisateur"
            item-value="idRessource"
            :search-input.sync="searchInputSuppleants"
            multiple
            clearable
            chips
            auto-select-first
            deletable-chips
            dense
            @change="searchInputSuppleants = ''"
          ></v-autocomplete>
        </v-col>

        <v-col
          v-if="nomRole === 'ADMIN' || utilisateur.ressources.length > 1"
          cols="12"
        >
          <v-select
            v-model="projetAModifier.idEntreprise"
            :test-auto="nomPage + '_entreprise'"
            hide-details
            label="Entreprise*"
            required
            :items="listEntreprise"
            item-text="nomEntreprise"
            item-value="idEntreprise"
          ></v-select>
        </v-col>

        <v-col v-if="mappingRessource.length > 0">
          <v-data-table
            :headers="headersRessource"
            :items="mappingRessource"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:top>
              <div>Veuillez attribuer les ressources correspondantes*</div>
            </template>
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.item.name }}
                </td>
                <td>
                  <v-autocomplete
                    v-model="attribuedRessource[props.item.name]"
                    label="Choisissez une ressource"
                    required
                    hide-details
                    :items="listeUtilisateursComplete"
                    item-text="labelUtilisateur"
                    item-value="idRessource"
                    dense
                  ></v-autocomplete>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapGetters, mapState } from "vuex";
import Api from "@/api/kuberact";
import RULES from "@/utils/enums";
import { EventHub } from "@/event-hub";
import xlsx from "xlsx";
import _ from "lodash";

const errorUpload = {
  msg: "Veuillez choisir un fichier excel.",
  color: "error",
  icon: "mdi-alert",
};

const errorExcel = {
  msg: "Fichier excel invalide ou trop volumineux.",
  color: "error",
  icon: "mdi-alert",
};

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    idProjetAModifier: {
      type: Number,
      default: undefined,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    projetAModifier: {},
    rules: RULES,
    searchInputSuppleants: "",
    menuDateDebut: false,
    menuDateCloture: false,
    fichier: null,
    excelData: null,
    mappingRessource: [],
    attribuedRessource: {},
    headersRessource: [
      { text: "Nom", value: "name", align: "center", sortable: false },
      {
        text: "Selection",
        value: "select",
        align: "center",
        sortable: false,
      },
    ],
    addedTask: [],
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "projets", "entreprises"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", [
      "nomRole",
      "ListRessourceToEntrepriseIdList",
      "listEntreprise",
    ]),
    ...mapGetters("entreprise", [
      "utilisateurAndProjetInSameEntreprise",
      "getIdRessourceByProjet",
      "getidRessourceByIdEntreprise",
    ]),
    editionMode: function() {
      return this.idProjetAModifier !== null
        ? this.idProjetAModifier > 0
        : null;
    },
    listeUtilisateursComplete: function() {
      return Object.values(this.utilisateurs)
        .filter(
          (utilisateur) =>
            utilisateur.actif &&
            this.utilisateurAndProjetInSameEntreprise(
              utilisateur,
              this.projetAModifier
            )
        )
        .map((utilisateur) => ({
          idRessource: this.getIdRessourceByProjet(
            utilisateur,
            this.projetAModifier
          ),
          labelUtilisateur: `${utilisateur.prenom} ${utilisateur.nom}`,
        }))
        .sort((a, b) => a.labelUtilisateur.localeCompare(b.labelUtilisateur));
    },
    dateDebutFormatee: function() {
      if (!this.projetAModifier.dateDebut) return null;
      const [annee, mois, jour] = this.projetAModifier.dateDebut.split("-");
      return `${jour} / ${mois} / ${annee}`;
    },
    dateClotureFormatee: function() {
      if (!this.projetAModifier.dateCloture) return null;
      const [annee, mois, jour] = this.projetAModifier.dateCloture.split("-");
      return `${jour} / ${mois} / ${annee}`;
    },
    //
  },
  watch: {
    idProjetAModifier: function(newidProjetAModifier) {
      this.loading = true;
      this.recupererLeProjet(newidProjetAModifier);
      this.loading = false;
    },
    //
  },
  created() {
    //
  },
  mounted() {
    this.recupererLeProjet(this.idProjetAModifier);
    this.loading = false;
    // clear previous
    this.fichier = null;
    this.excelData = null;
    this.mappingRessource = [];
    this.attribuedRessource = {};
    this.addedTask = [];
  },
  methods: {
    ...mapActions("entreprise", [
      "createProjet",
      "updateProjet",
      "deleteProjet",
      "createTache",
    ]),
    getLocaleDateIsoFormat() {
      return new Date().toISOString().slice(0, 10);
    },
    initialiserDateCloture() {
      this.projetAModifier.dateCloture = this.projetAModifier.actif
        ? null
        : this.getLocaleDateIsoFormat();
    },
    clearData() {
      this.projetAModifier = {};
    },
    supprimerProjet() {
      this.loading = true;
      this.deleteProjet(this.idProjetAModifier)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .finally(() => (this.loading = false));
    },
    async sauvegarderProjet() {
      let vm = this;
      this.saving = true;
      const processProjet = this.editionMode
        ? this.updateProjet
        : this.createProjet;

      await processProjet(this.projetAModifier)
        .then(async (resp) => {
          // add de tache si import excel
          if (!this.editionMode && vm.excelData) {
            await this.addTasksToProj(resp);
          }
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    recupererLeProjet(idProjet) {
      if (idProjet !== null) {
        this.projetAModifier = {};
        if (!this.editionMode) {
          // Default value on element creation
          this.projetAModifier = {
            actif: true,
            dateDebut: this.getLocaleDateIsoFormat(),
            idChefDeProjet: this.getidRessourceByIdEntreprise(
              this.utilisateur,
              this.utilisateur.idEntreprise
            ),
            idRessourceCreateur: this.getidRessourceByIdEntreprise(
              this.utilisateur,
              this.utilisateur.idEntreprise
            ),
            idEntreprise: this.utilisateur.idEntreprise,
            numAffaire: "",
            listeIdsRessourceSuppleant: [],
            listeSuppleantsInitiale: [],
          };
        } else if (this.editionMode && idProjet in this.projets) {
          // Copy only what's needed here
          const projetEnMemoire = { ...this.projets[idProjet] };
          this.projetAModifier = {
            idChefDeProjet: projetEnMemoire.idChefDeProjet,
            idEntreprise: projetEnMemoire.idEntreprise,
            dateCloture: projetEnMemoire.dateCloture,
            numAffaire: projetEnMemoire.numAffaire,
            nomProjet: projetEnMemoire.nomProjet,
            dateDebut: projetEnMemoire.dateDebut,
            idProjet: projetEnMemoire.idProjet,
            actif: projetEnMemoire.actif,
            listeIdsRessourceSuppleant: projetEnMemoire.suppleants.map(
              (suppleant) => suppleant.idRessource
            ),
            listeSuppleantsInitiale: projetEnMemoire.suppleants,
          };
        } else {
          this.$emit("fermer");
          this.clearData();
        }
      }
    },
    // part about excel
    importExcel(e) {
      const files = e.target.files;
      if (!files.length) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        EventHub.$emit("AFFICHER_NOTIFICATION", errorUpload);
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          const excellist = [];
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.excelData = excellist;
          // fichier trop gros, reject
          if (this.excelData.length === 0 || this.excelData.length > 5000) {
            EventHub.$emit("AFFICHER_NOTIFICATION", errorExcel);
            return false;
          }
          // fichier qui ne contient pas les bonnes infos, reject
          if (!this.checkKeys()) {
            EventHub.$emit("AFFICHER_NOTIFICATION", errorExcel);
            return false;
          }

          this.processFile(excellist);
        } catch (f) {
          EventHub.$emit("AFFICHER_NOTIFICATION", errorUpload);
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    },
    processFile(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].Noms_ressources) {
          this.addRessource(data[i].Noms_ressources);
        }
      }
    },
    addRessource(str) {
      const vm = this;
      const tmp = str.split(";");
      tmp.forEach(function(e) {
        if (
          !_.find(vm.mappingRessource, function(o) {
            return o.name === e;
          })
        ) {
          vm.mappingRessource.push({ value: 0, name: e });
        }
      });
    },
    async addTasksToProj(idProj) {
      const self = this;
      for (let index = 0; index < this.excelData.length; index++) {
        if (self.getTacheMere(this.excelData[index]) !== false) {
          // add des taches
          var avance =
            Math.round(this.excelData[index]["Pourcentage_achevé"] * 10) / 10;
          var tache = {
            actif: true,
            couleur: "#1976D2",
            idProjet: idProj,
            idRessourceCreateur: this.utilisateur.idRessource,
            idRessourceResponsable: this.projetAModifier.idChefDeProjet,
            idTacheMere: self.getTacheMere(this.excelData[index]),
            nom: this.excelData[index].Nom,
            tempsPrevu: 0,
            avancement: avance,
          };
          await this.createTache(tache).then(async (task) => {
            //TODO verifier l'utiliter du await
            self.addedTask[task.nom] = task;
            // add des ressourses
            self.addRessourcesInTasks(
              task.nom,
              this.excelData[index].Noms_ressources
            );
            return true; //TODO devrait etre supprimer
          });
        }
      }
      await this.addRessourcesInTasks(); //TODO devrait etre supprimer
      return true; //TODO devrait etre suprimer
    },
    getTacheMere(tache) {
      // si tache fille, recup de l'id de la tache mere qui doit etre en store
      if (tache["Nº_hiérarchique"].includes(".")) {
        // get hierachie mere pour retrouver son nom dans l'export excel
        const hierarchieMere = tache["Nº_hiérarchique"].slice(
          0,
          tache["Nº_hiérarchique"].lastIndexOf(".")
        ); // delete le .1 par exemple
        // get tache mere pour avoir son name et la retrouver dans addedTask
        const mere = this.excelData.filter((tacheM) => {
          return tacheM["Nº_hiérarchique"] === hierarchieMere;
        });
        // return id tache mere pour add tache
        if (mere.length > 0) {
          return this.addedTask[mere[0].Nom].idTache;
        } else {
          return false;
        }
      }
      return "";
    },
    async addRessourcesInTasks(tache, ressources) {
      if (ressources !== undefined) {
        // get id de la tache tt juste ajouter
        const ress = ressources.split(";");
        // boucle sur chacune des ressourses
        for (let index = 0; index < ress.length; index++) {
          Api.createTravailleur({
            idRessource: this.attribuedRessource[ress[index]],
            idTache: this.addedTask[tache].idTache,
          });
        }
      }
      return true;
    },
    checkKeys() {
      // colonnes normalement presentes dans le fichier
      const key = [
        "Début",
        "Fin",
        "Nom",
        "N°",
        "Nº_hiérarchique",
        "Pourcentage_achevé",
        "Noms_ressources",
      ];
      const key2 = Object.keys(this.excelData[0]);

      // intersection des 2 tableaux
      const res = key.filter((value) => key2.includes(value));

      // si pas les 6 colonnes obligatoires, je considere le fichier incorrect
      if (res.length === 6) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
