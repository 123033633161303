import Vue from "vue";
import * as types from "./mutation_types";
import { convertArrayToObject } from "@/utils/transform";
import { calculInidicateursCumuleesSurDetailsProjets } from "./utils";

export default {
  [types.SET_UTILISATEURS](state, utilisateurs) {
    let utilisateursFormat={};
    utilisateurs.forEach(utilisateur => {
        utilisateur['ressources'].forEach(ressource=>{
          utilisateursFormat[ressource["idRessource"]] = utilisateur;
        });
      
    });
    Vue.set(
      state,
      "utilisateurs",
      utilisateursFormat
    );
  },
  [types.SET_ENTREPRISES](state, entreprises) {
    Vue.set(
      state,
      "entreprises",
      convertArrayToObject(entreprises, "idEntreprise")
    );
  },
  [types.REPLACE_ENTREPRISE](state, entreprise) {
    Vue.set(state.entreprises, entreprise.idEntreprise, entreprise);
  },
  [types.SET_PROFILS](state, profils) {
    
    Vue.set(
      state,
      "profils",
      profils.reduce(
        (obj, item) => ({
          ...obj,
          [[item["idEntreprise"],item["idRole"]]]: item,
        }),
        {}
      )
    );
  },
  [types.ADD_OR_REPLACE_PROFIL](state, profil) {
    
    Vue.set(
      state.profils,
      [[profil.idEntreprise,profil.idRole]],
      profil
    );
  },
  [types.SET_PROJETS](state, projets) {
    Vue.set(state, "projets", convertArrayToObject(projets, "idProjet"));
  },
  [types.SET_ROLES](state, roles) {
    Vue.set(state, "roles", convertArrayToObject(roles, "idRole"));
  },
  [types.SET_GROUPES](state, groupes) {
    Vue.set(state, "groupes", convertArrayToObject(groupes, "idGroupe"));
  },
  [types.ADD_OR_REPLACE_DETAILS_PROJETS](state, objetDetailsProjets) {
    // Formatage pour calcul récursif et stockage
    const objetDetailsProjetsFormate = {};
    Object.keys(objetDetailsProjets).forEach((idProjet) => {
      objetDetailsProjetsFormate[idProjet] = {};
      objetDetailsProjetsFormate[idProjet].idProjet = idProjet;
      objetDetailsProjetsFormate[idProjet].filles =
        objetDetailsProjets[idProjet];
    });
    // Calcul récursif
    calculInidicateursCumuleesSurDetailsProjets(objetDetailsProjetsFormate);

    // Stockage
    Object.keys(objetDetailsProjetsFormate).forEach((idProjet) => {
      Vue.set(
        state.detailsProjets,
        idProjet,
        objetDetailsProjetsFormate[idProjet]
      );
    });
  },
  [types.ADD_OR_REPLACE_UTILISATEUR](state,{utilisateur,listEntrepriseUtilisateur}) {
    for (const ressource of utilisateur.ressources) {
      if(listEntrepriseUtilisateur.includes(ressource.idEntreprise)){
        Vue.set(state.utilisateurs, ressource.idRessource, utilisateur);
      }
    }
  },
  [types.ADD_OR_REPLACE_PROJET](state, projet) {
    Vue.set(state.projets, projet.idProjet, projet);
  },
  [types.REMOVE_PROJET](state,idProjet) {
    Vue.delete(state.projets, idProjet);
  },
  [types.ADD_OR_REPLACE_GROUPE](state, groupe) {
    Vue.set(state.groupes, groupe.idGroupe, groupe);
  },
  [types.REMOVE_GROUPE](state, idGroupe) {
    Vue.delete(state.groupes, idGroupe);
  },
  [types.REMOVE_TACHE_DE_DETAILS_PROJETS](state, { idTache, idProjet }) {
    // Commencer par un noeud projet
    let tacheTrouvee = false;
    function findTacheAndDelete(noeud) {
      if (Array.isArray(noeud.filles) && noeud.filles.length) {
        if (!tacheTrouvee) {
          const fillesFiltrees = noeud.filles.filter(
            (fille) => fille.idTache !== idTache
          );
          if (fillesFiltrees.length !== noeud.filles.length) {
            tacheTrouvee = true;
            noeud.filles = fillesFiltrees;
          } else {
            noeud.filles.forEach((fille) => findTacheAndDelete(fille));
          }
        }
      }
    }
    if (idProjet in state.detailsProjets) {
      findTacheAndDelete(state.detailsProjets[idProjet]);
      calculInidicateursCumuleesSurDetailsProjets(state.detailsProjets);
    } else {
      console.log("project not found");
    }
    
  },

  //SET ENTREPRISE SELECT
  [types.SET_ENTREPRISE_SELECTIONNEE](state, entreprises) { 
    Vue.set(state.configuration, "entrepriseSelectionnee", entreprises);
  }, 

  [types.SET_UTILISATEURS_BY_ENTREPRISE_SELECTIONNEE](state, utilisateurs, idEntrepriseSelectionnee)
   {
    let utilisateursByEntreprise={};   
    idEntrepriseSelectionnee = state.configuration.entrepriseSelectionnee.idEntreprise;
    utilisateurs.forEach(utilisateur => {
        utilisateur['ressources'].forEach(ressource=>{
          if (ressource["idEntreprise"] === idEntrepriseSelectionnee) {
            utilisateur.ressources[0] = ressource;
            utilisateursByEntreprise[ressource["idRessource"]] = utilisateur;           
          }
        });     
    });
      Vue.set(state.configuration, "utilisateursByEntrepriseSelectionnee",
      utilisateursByEntreprise);
  },

};//fin export
