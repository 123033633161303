<template>
  <v-row no-gutters justify="center">
    <v-dialog
      :value="true"
      persistent
      scrollable
      :max-width="maximum"
      @keydown.esc="initierFermeture"
    >
      <v-skeleton-loader
        v-if="loading"
        :loading="loading"
        type="article, list-item-two-line, actions"
        style="background-color: white"
      />
      <v-card v-show="!loading">
        <v-card-title class="justify-center">
          <span class="headline">{{ titre }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formulaireValide">
            <v-container>
              <v-row class="align-center">
                <slot name="champs"> </slot>
              </v-row>
            </v-container>
          </v-form>
          <small v-show="afficherMessageChampsObligatoire"
            >*indique les champs obligatoires</small
          >
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="supprimable"
            :test-auto="nomPage+'_supprimer'"
            dark
            color="red darken-3"
            @click="$emit('supprimer')"
          >
            <v-icon class="pr-1">mdi-delete-circle</v-icon> Supprimer
          </v-btn>
          <v-spacer v-if="supprimable"></v-spacer>
          <v-btn color="blue darken-1" 
            :test-auto="nomPage+'_fermer'" 
            text @click="initierFermeture">
            Fermer
          </v-btn>
          <v-spacer v-if="!supprimable"></v-spacer>
          <v-btn
            :loading="saving"
            :test-auto="nomPage+'_sauvegarder'"
            color="green darken-1"
            :disabled="!formulaireValide"
            text
            @click="validerEtSauvegarder"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    maximum: {
      type : String,
      default: "600px"
    },
    titre: {
      type: String,
      default: "",
    },
    supprimable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    afficherMessageChampsObligatoire: {
      type: Boolean,
      default: true,
    },
    nomPage:{
      type: String,
      default: "",
    }
  },
  data: () => ({
    formulaireValide: true,

    //
  }),
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    initierFermeture() {
      this.$emit("fermer");
    },
    validerEtSauvegarder() {
      if (this.$refs.form.validate()) {
        this.$emit("sauvegarder");
      }
    },
  },
};
</script>

<style scoped>

</style>
