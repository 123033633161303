<template>
  <!-- v-on="$listeners" enables event to go to from grand-child 
  (i.e. formulaire-pop-up) to parent (i.e Accueil).
  Here, we use it to trigger the closing form -->
  <div>
    <formulaire-pop-up
      :titre="`${editionMode ? 'Modifier' : 'Créer'} une tâche`"
      :nom-page="nomPage"
      :supprimable="false"
      :loading="loading"
      :saving="saving"
      @sauvegarder="sauvegarderTache"
      @supprimer="supprimerTache"
      v-on="$listeners"
    >
      <template #champs="">
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="tache.nom"
            :test-auto="nomPage + '_nom'"
            autofocus
            :rules="[rules.nonVide]"
            hide-details
            maxlength="150"
            label="Nom*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="tache.idRessourceResponsable"
            :test-auto="nomPage + '_responsable'"
            label="Responsable*"
            required
            hide-details
            :items="listeUtilisateursComplete"
            item-text="labelUtilisateur"
            item-value="idRessource"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model.number="tache.tempsPrevu"
            :test-auto="nomPage + '_tempsPrevu'"
            min="0"
            type="number"
            hide-details
            label="Temps prévu (en JH)"
            @input="if (tache.tempsPrevu < 0) tache.tempsPrevu = 0;"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="configuration.tacheSelectionnee.tempsPointe !== 0"
          cols="12"
          sm="4"
        >
          <v-text-field
            v-if="editionMode"
            v-model.number="tache.tempsResteAFaire"
            :test-auto="nomPage + '_tempsResteAFaire'"
            min="0"
            hide-details
            label="Reste à faire* (en JH)"
            required
            type="number"
            @input="if (tache.tempsResteAFaire < 0) tache.tempsResteAFaire = 0;"
          ></v-text-field>
        </v-col>
        <v-col v-if="editionMode" cols="12" sm="4">
          <v-switch
            v-model="tache.actif"
            :test-auto="nomPage + '_actif'"
            inset
            hide-details
            color="green"
            :label="tache.actif ? 'Tâche active' : 'Tâche désactivée'"
          ></v-switch>
        </v-col>
        <v-col v-if="!editionMode" cols="12" sm="8">
          <v-slider
            v-model="sliderAvancement"
            label="Avancement (%)"
            dense
            class="align-center"
            track-color="blue-grey lighten-4"
            :color="tache.couleur"
            max="100"
            min="0"
            step="1"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="sliderAvancement"
                :test-auto="nomPage + '_avencement'"
                class="mt-0 pt-0 mr-4"
                label="Avancement (%)"
                hide-details
                min="0"
                max="100"
                single-line
                type="number"
                style="width: 45px"
              >
              </v-text-field>
            </template>
          </v-slider>
        </v-col>
        <v-col cols="12" sm="12">
          <v-sheet :color="tache.couleur" class="flex-grow-1 text-center">
            <v-btn
              :color="couleurEstClaire ? 'black' : 'white'"
              :test-auto="nomPage + '_couleur'"
              width="100%"
              text
              @click="setRandomColor"
            >
              Changer la couleur
            </v-btn>
          </v-sheet>
        </v-col>
      </template>
    </formulaire-pop-up>
  </div>
</template>

<script>
import FormulairePopUp from "@/components/utils/FormulairePopUp";
import { mapActions, mapGetters, mapState } from "vuex";
import RULES from "@/utils/enums";
import { getContrastYIQ } from "@/utils/transform";

export default {
  components: {
    FormulairePopUp,
  },
  props: {
    // 'idTache' correspond à l'id de la mère pour une
    // création OU l'id de la tâche à modifier pour une modification
    // Pour la création d'une tache, on spécifiera id: 0 pour faciliter les
    // traitement mais si ce n'est pas imposé par l'API
    idTache: {
      type: Number,
      default: null,
    },
    // Besoin d'un idProjet lors d'une création
    idProjet: {
      type: Number,
      default: null,
    },
    nomPage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    saving: false,
    tache: {},
    rules: RULES,
    sliderAvancement: 0, // l'avancement 0...100
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "projets"]),
    ...mapState("utilisateur", ["utilisateur", "configuration"]),
    ...mapGetters("entreprise", [
      "utilisateurAndTacheInSameEntreprise",
      "getIdRessourceByTache",
      "getIdRessourceByProjet",
    ]),
    editionMode: function() {
      return this.idProjet === null && this.idTache > 0;
    },
    listeUtilisateursComplete: function() {
      return Object.values(this.utilisateurs)
        .filter(
          (utilisateur) =>
            utilisateur.actif &&
            this.utilisateurAndTacheInSameEntreprise(utilisateur, this.tache)
        )
        .map((utilisateur) => ({
          idRessource: this.getIdRessourceByTache(utilisateur, this.tache),
          labelUtilisateur: `${utilisateur.prenom} ${utilisateur.nom}`,
        }))
        .sort((a, b) => a.labelUtilisateur.localeCompare(b.labelUtilisateur));
    },
    couleurEstClaire() {
      return getContrastYIQ(this.tache.couleur);
    },
  },
  watch: {
    idTache: function() {
      this.initialiserLaTache();
    },
    sliderAvancement: function() {
      this.tache.avancement = this.sliderAvancement / 100;
    },
  },
  created() {
    this.initialiserLaTache();
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["createTache", "updateTache", "deleteTache"]),
    setRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.tache.couleur = color;
    },
    clearData() {
      this.tache = {};
    },
    supprimerTache() {
      this.loading = true;
      this.deleteTache(this.tache)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .finally(() => (this.loading = false));
    },
    sauvegarderTache() {
      this.saving = true;
      const processTache = this.editionMode
        ? this.updateTache
        : this.createTache;

      processTache(this.tache)
        .then(() => {
          this.$emit("fermer");
          this.clearData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    initialiserLaTache() {
      // config needs idProjet, idTacheMere for creation, idTache for edition
      if (this.idTache !== null) {
        this.tache = {};
        if (!this.editionMode && this.idProjet > 0 && this.idTache >= 0) {
          // Default value on element creation
          this.tache = {
            idRessourceResponsable: this.getIdRessourceByProjet(
              this.utilisateur,
              this.projets[this.idProjet]
            ),
            idRessourceCreateur: this.getIdRessourceByProjet(
              this.utilisateur,
              this.projets[this.idProjet]
            ),
            // l'API n'accepte pas une tâche mère à zéro lors
            // de la création à la racine donc on force à null
            idTacheMere: this.idTache ? this.idTache : null,
            couleur: "#ffffff",
            tempsPrevu: 1,
            avancement: 0,
            idProjet: this.idProjet,
            actif: true,
          };
          this.setRandomColor();
          this.sliderAvancement = 0;
        } else if (
          this.editionMode &&
          this.idTache === this.configuration.tacheSelectionnee.idTache
        ) {
          // Seule la tâche qui est sélectionnée peut être modifiée
          // Copy only what's needed here
          const tacheEnMemoire = { ...this.configuration.tacheSelectionnee };
          this.tache = {
            actif: tacheEnMemoire.actif,
            // 'avancement' à repasser sur une base 0...1 avant la sauvegarde
            couleur: tacheEnMemoire.couleur,
            nom: tacheEnMemoire.nom,
            idTache: tacheEnMemoire.idTache,
            idProjet: tacheEnMemoire.idProjet,
            idRessourceResponsable: tacheEnMemoire.idRessourceResponsable,
            tempsPrevu: tacheEnMemoire.tempsPrevu.toFixed(1),
            tempsResteAFaire: tacheEnMemoire.tempsResteAFaire.toFixed(1),
          };
        } else {
          this.$emit("fermer");
          this.clearData();
        }
      }
    },
    //
  },
};
</script>

<style scoped></style>
