// convertArrayToObject([
//     { id: '1', name: 'June' },
//     { id: '3', name: 'Harry' },], 'id');
// Returns
// {
//  '1': { id: '1', name: 'June' },
//  '3': { id: '3', name: 'Harry' },
// }
const convertArrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {}
  );

const getContrastYIQ = (hexColorCode) => {
  // Compute which color the text should be based on background image
  // True means text should be black, False means white
  // yiq >= 128 to 160 seems good
  const [r, g, b] = [0, 2, 4].map((color) =>
    parseInt(hexColorCode.replace("#", "").substr(color, 2), 16)
  );
  return (r * 299 + g * 587 + b * 114) / 1000 >= 160;
};

const timestampToHeure= (timestamp) => {
  let date = new Date(timestamp);
  // Hours part from the timestamp
  let hours = "0" + date.getHours();
  // Minutes part from the timestamp
  let minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  let seconds = "0" + date.getSeconds();
  // Will display time in 10:30:23 format
  return (
    hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2)
  );
};
const getDateOfTimestamp=(timestamp)=> {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let month = "0" + (date.getMonth() + 1);
  let day = "0" + date.getDate();
  return year + "-" + month.substr(-2) + "-" + day.substr(-2);
};
const heureFormaterToMinutes= (heure)=> {
  //entre 12:00:00 -> sortie 720
  let  heureFin = heure.split(":");
  return heureFin[0] * 60 + heureFin[1] * 1;
};
const minutesToHeureFormater= (minutes)=> {
  //entre 720 -> sortie 12:00:00 
  const h = "0"+Math.floor(minutes / 60);
      const m = "0" + Math.floor((minutes % 60));
      return h.substr(-2) + ":" + m.substr(-2)+":00";
};

const formatFloat= (value)=> {
  value= parseFloat(value).toFixed(1) 
  return Math.round(value * 100) / 100;
};

export { convertArrayToObject, getContrastYIQ,timestampToHeure,getDateOfTimestamp,heureFormaterToMinutes,minutesToHeureFormater,formatFloat };
